import styled from "styled-components";
import {
  corPrimaria,
  corSecundaria,
  corTerciaria,
  temaPrimario,
} from "./GlobalStyles";

export const GoogleMapsSections = styled.section`
  width: 100%;
  margin: 0 auto;
  border: none;
  overflow: hidden;
  @media (min-width: 993px) and (max-width: 1200px) {
    margin-top: 100px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 100px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    margin-top: 100px;
  }
  @media (max-width: 576px) {
    margin-top: 100px;
  }
`;

export const GoogleMaps = styled.iframe`
  width: 100%;
  height: 700px;
`;
export const ServicosDiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: -80px;
  width: 80%;
  background: ${corSecundaria};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-around;
  border: 10px solid;
  z-index: 2;
  border-image: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  border-image-slice: 1;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
export const ServicosIcon = styled.img`
  width: 60px;
  margin-left: 35px;
  transform: rotate(45deg);
`;
export const ServicosLink = styled.a`
  border: 2px solid ${temaPrimario};
  padding: 10px 20px;
  color: ${temaPrimario};
  transition: 0.5s ease-in-out;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    transition: 0.5s ease-in-out;
    background: ${temaPrimario};
    color: ${corSecundaria};
  }
`;
export const ServicosText = styled.p`
  margin: 20px;
  width: 80%;
  text-align: justify;
  font-size: 1.125em;
  color: ${temaPrimario};
  @media (max-width: 576px) {
    font-size: 1em;
  }
`;
