import React from "react";
import Data from "../images/data-limite.png";
import Mala from "../images/pasta.png";
import Financa from "../images/financa.png";
import * as S from "../styles/DiferencialStyles";
export const Diferencial = () => {
  return (
    <S.DiferencialSections>
      <S.DiferencialSubTitle>Diferencial</S.DiferencialSubTitle>
      <S.DiferencialTitle>
        Temos diferenciais que nos destacam dos concorrentes.
      </S.DiferencialTitle>

      <S.DiferencialContent>
        <S.DiferencialCard>
          <S.DiferencialCardImg src={Data} alt="Disponibilidade" />
          <S.DiferencialCardTitle>Disponibilidade</S.DiferencialCardTitle>
          <S.DiferencialCardText>
            Estamos preparados para um novo desafio e queremos contribuir ao
            maximo.
          </S.DiferencialCardText>
          <S.DiferencialCardLink to="/contato">
            Agendar uma reunião
          </S.DiferencialCardLink>
        </S.DiferencialCard>
        <S.DiferencialCard>
          <S.DiferencialCardImg src={Financa} alt="Finanças" />
          <S.DiferencialCardTitle>Custo Beneficío</S.DiferencialCardTitle>
          <S.DiferencialCardText>
            Queremos garantir o máximo benefício pelo menor custo possível.
          </S.DiferencialCardText>
          <S.DiferencialCardLink to="/contato">
            Agendar uma reunião
          </S.DiferencialCardLink>
        </S.DiferencialCard>
        <S.DiferencialCard>
          <S.DiferencialCardImg src={Mala} alt="Negocios" />
          <S.DiferencialCardTitle>
            Todos tipos de negócios
          </S.DiferencialCardTitle>
          <S.DiferencialCardText>
            Maximizando o Valor para Todos os Negócios Com Custos Acessíveis.
          </S.DiferencialCardText>
          <S.DiferencialCardLink to="/contato">
            Agendar uma reunião
          </S.DiferencialCardLink>
        </S.DiferencialCard>
      </S.DiferencialContent>
    </S.DiferencialSections>
  );
};
