import React from "react";
import { ScrollListener } from "../context/MenuFixo";
import Logo from "../images/logo.svg";
import VideoBanner from "../videos/ecommerce.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,

  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import useFraseDigitando from "../context/useFraseDigitando";
import * as S from "../styles/HeaderStyles";
import { MenuMobile } from "./MenuMobile";

export const Header = () => {
  const fraseDigitando = useFraseDigitando();

  return (
    <>
      <S.HeaderMenu>
        <S.ContentTitle id="frase">
          Soluções em&nbsp;
          <S.ContentText id="frase-dinamica">{fraseDigitando}</S.ContentText>|
        </S.ContentTitle>
        <ScrollListener />
        <S.HeaderMenuContent id="menu-fixo">
          <S.HeaderMenuDiv>
            <S.LogoLink to="/">
              <S.HeaderMenuImg src={Logo} alt="Logo Ecommerce Smart" />
            </S.LogoLink>
          </S.HeaderMenuDiv>
          <S.HeaderMenuNav>
            <S.HeaderMenuLink to="/">Home</S.HeaderMenuLink>
            <S.HeaderMenuLink to="/servicos">Serviços</S.HeaderMenuLink>
            <S.HeaderMenuLink to="/sobre">Sobre</S.HeaderMenuLink>
            <S.HeaderMenuLink to="/contato">Contato</S.HeaderMenuLink>
            <S.HeaderMenuLink to="/clientes">Clientes</S.HeaderMenuLink>
            <S.HeaderMenuLink
              to="https://www.instagram.com/e_commercesmart/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </S.HeaderMenuLink>
            <S.HeaderMenuLink>
              <FontAwesomeIcon icon={faLinkedin} />
            </S.HeaderMenuLink>
          </S.HeaderMenuNav>
        </S.HeaderMenuContent>
        <S.VideoMenu id="video-menu" src={VideoBanner} autoPlay loop muted />
      </S.HeaderMenu>
      <MenuMobile />
    </>
  );
};
