import React from "react";
import * as S from "../styles/Politica";
export const PoliticaComponents = () => {
  return (
    <S.PoliticaSections>
      <S.PoliticaTitle>Política de Privacidade</S.PoliticaTitle>
      <S.PoliticaText>
        <b>1.</b> Esta Política de Privacidade se refere aos dados pessoas que
        o(a) E-commerce Smart poderá obter quando seus Usuários utilizam dos
        serviços prestados durante a navegação dentro do nosso ambiente virtual.
        Nesta Política de Privacidade, será descrita de que forma serão
        coletados e armazenados dados a respeito dos Usuários, assim como a
        forma pela qual tais dados poderão ser utilizados e armazenados nos
        termos da Lei 12.965/2014("Marco Civil da Internet"), do Decreto
        8.771/2016 e da Lei nº 13.709/2018(Lei Geral de Proteção de Dados).{" "}
        <br />
        <br />
        Ao aceitar a presente Política de Privacidade é declarado que todo seu
        conteúdo foi devidamente lido e aceito, tendo assim todas as cláusulas
        devidamente validadas pelo Usuário para que atuem em pleno vigor. <br />
        <br />
        Ademais, deixa claro que está Política de Privacidade poderá ser
        atualizada a qualquer tempo, a exclusivo critério dos administradores do
        ambiente virtual aqui discutido. Porém, deixa claro que toda e qualquer
        comunicação será devidamente comunicada aos Usuários para nova aceitação
        de modo de que, caso continuem em utilizar os serviços providenciados,
        estarão automaticamente concordando com as alterações realizadas no
        corpo dessa política.
        <br />
        <br />
        <b>Obtenção, Armazernamento e Cuidado dos dados pessoais coletados.</b>
        <br />
        <br />
        <b>2.</b> Aceitando nossa Política de Privacidade, fica concedido, por
        livre e espontânea vontade para a coleta e armazenamento dos dados
        pessoais do Usuário, que serão tratados da forma abaixo descrita:
        <br />
        <br />
        <S.Lista>
          Poderão ser coletados informações fornecidas no cadastro ao longo do
          uso dos Serviços e durante a sua navegação em nossa plataforma.
        </S.Lista>
        <br />
        <S.Lista>
          Eventuais dados de navegação serão utilizados para possibilitar o seu
          acesso e saber como entrar em contato com o Usuário quando for
          necessário. Além de utilizados para compreender seus interesses, como
          forma de garantir constante melhoria e evolução dos serviços providenciados.
        </S.Lista>
        <br />
        <S.Lista>
          Os dados pessoais, poderão ser armazenados em outros países aonde o(a)
          E-commerce Smart ou suas afiliadas possuam presença, Caso tais
          jurisdições possuam diferentes leis de proteção de dados, fica
          estabelecido o compromisso de armazenar e cuidar dos dados de acordo
          com tais leis e a presente Política de Privacidade.
        </S.Lista>
        <br />
        <S.Lista>
          Os dados pessoais poderão ser utilizados de forma anônima pra fins
          estatístico e de controle e melhoria dos nossos serviços.
        </S.Lista>
        <br />
        <S.Lista>
          Serão estabelecidas medidas de segurança razoáveis para proteger todos
          os dados pessoais providenciados. Entretanto, lembramos que não existe
          uma medida de segurança 100% eficaz.
        </S.Lista>
        <br />
        <S.Lista>
          Eventualmente, todos os dados coletados pelo Usuário durante o uso dos
          serviços prestados, poderão ser excluídos ao momento que o Usuário
          desejar, dito isso, somos obrigados a manter os registros de conexão
          durante o prazo legal.
        </S.Lista>
        <br />
        <S.Lista>
          Esta Política de Privacidade se aplica a todos os Websites detidos
          pelo(a) E-commerce Smart ou qualquer outra página, mídia social,
          ferramenta, software ou conteúdo de sua propriedade.
        </S.Lista>
        <br />
        <br />
        <b>3.</b> O fato de usar o site e / ou serviços disponibilizado confirma
        o consentimento inequívoco e incondicional do Usuário com esta Política,
        incluindo os termos de processamento de seus dados pessoais. Na ausência
        de consentimento do Usuário com esta Política e os termos de
        processamentos de seus dados pessoais, o Usuário deve para de usar o
        site e / ou serviços providênciando, reservando à administração o
        direito de impedir o acesso do referido Usuário.
        <br />
        <br />
        <b>4.</b> Este ambiente é destinado a Usuários com 18 (dezoito) anos de
        idade ou mais.{" "}
        <b>
          Se você tem menos de 18 (dezoito) anos, não poderá usar ou
          registrar-se para usar este site ou seus serviços sem a permissão ou
          consentimento dos pais.
        </b>{" "}
        Ao concordar com esta Política de Privacidade, você tem capacidade legal
        necessária para cumprir e ficar vinculado por esta Política de
        Privacidade.
        <br />
        <br />
        <b>Armazenamento de Informações</b>
        <br /> <br />
        <b>5.</b> É reconhecida a natureza sensível e confidencial dos dados e
        demais informações obtidas e armazenadas. Assim fica estabelecido o
        compromisso de manter estas informações de natureza confidencial em
        sigilo, sem utilizá-las ou divulga-las sem autorização do Usuário,
        exceto nos termos previstos nos Termos de Uso e na Política de
        Privacidade, bem como nos limites necessários para a execução das
        obrigações contratuais e legais, assim como para defesa dos interesses
        do(a) E-commerce Smart e dos Usuários.
        <br /> <br />
        <b>Cuidado com as informações e uso de Cookies</b>
        <br />
        <br />
        <b>6.</b> O Usuário concorda que o(a) E-commerce Smart poderá coletar,
        registrar, organizar, acumular, armazenar, atualizar, extrair, usar,
        transferir, incluindo transferência para outros países onde possua
        parceiros e / ou afiliadas, remover e destruir dados pessoais e outras
        informações.
        <br />
        <br />
        <b>7.</b> Os atos descritos acima poderão ser processados de forma
        manual e / ou com o uso de automação. O presente consentimento é válido
        até a sua retirada das configurações do Usuário e / ou até que seja
        solicitado pelo Usuário de forma direta. A solicitação pode ser enviada
        por escrito para o endereço eletrônico:{" "}
        <b>felipe.ecommercesmart@gmail.com</b>
        <br />
        <br />
        <b>8.</b> Dentro dos limites da legislação aplicável, o(a) E-commerce
        Smart tem o direito de transferir as informações fornecidas pelo Usuário
        para terceiros.
        <br />
        <br />
        <b>9.</b> No tratamento de dados pessoais, serão tomadas as medidas
        legais, técnica e organizacionais necessárias para proteger os dados
        pessoais contra o acesso não autorizado ou acidental, destruição,
        modificação, bloqueio, cópia, distribuição de dados pessoais, bem como
        outras ações legais em relação a dados pessoais em cumprimento dos
        requesitos da legislação brasileira e / ou qualquer outra que venha a
        ser aplicável. O Usuário concorda que algumas das informações, incluindo
        dados pessoais, ficam disponíveis publicamente ao usar determinados
        serviços e / ou o site.
        <br />
        <br />
        <b>10.</b> E-commerce Smart faz o uso de cookies. Ao acessar nosso site,
        você concorda em usar cookies nos termos da nossa Política de Cookies.
        Salienta-se que algum dos nossos parceiros de site podem usar cookies.
        <br />
        <br />
        <b>Manutenção dos dados pelo Usuário</b>
        <br />
        <br />
        <b>11.</b> O Usuário tem o direito de requerer a exclusão de seus dados
        pessoais coletados durante o uso do ambiente eletrônico disponibilizado
        a qualquer momento, utilizando-se serviço relevante disponibilizado pelo
        próprio ambiente, ou por meio de contato direto com a administração por
        meio do endereço eletrônico disponibilizado acima. Estes direitos podem
        ser restringidos da maneira prescrita pela a legislação brasileira.
        <br />
        <br />
        <b>12.</b> Caso o Usuário tenha ciência de que seus dados pessoais
        tenham sido comprometidos, de forma que poderá afetar seu acesso
        ambiente eletrônico providênciando, ele deverá comunicar imediatamente a
        administração para que sejam tomadas as medidas de segurança
        necessárias.
        <br />
        <br />
        <b>13.</b> O Usuário é o único responsável por suas ações relacionadas ao
        uso do site e / ou serviços disponibilizados, significando que, se tais
        ações resultarem em violação dos direitos e interesses legítimos de
        terceiros, bem como descumpre com a legislação do Brasil, o Usuário
        concorda em indenizar os prejuízos caudados ao(à) E-commerce Smart e /
        ou terceiros como resultado da nao execução ou má execução das
        obrigações do Usuário sob esta Política e / ou a legislação.
        <br />
        <br />
        <b>Responsabilidades e Competências</b>
        <br />
        <br />
        <b>14.</b> O Usuário usa os Serviços por sua conta em risco, sendo o
        único responsável por perdas incorridas como resultado do mal-uso pelo
        do ambiente e / ou dos serviços da Empresa.
        <br />
        <br />
        <b>15.</b> O(A) E-commerce Smart coopera com as autoridades competentes
        e com terceiros para garantir o cumprimento das leis, salvaguarda a
        integridade e a segurança da Plataforma e de seus Usuários, e impedir
        condutas que prejudiquem a integridade moral e a honra das pessoas
        físicas e jurídicas envolvidas.
        <br />
        <br />
        <b>16.</b> As disposições desta Política são regidas pelas leis do
        Brasil. Se, por uma razão ou outra, uma ou mais disposições desta
        Política forem consideradas inválidas, isso não afeta as demais
        disposições.
        <br />
        <br />
        <b>17.</b> Para todas as questões o Usuário pode enviar um pedido para o
        endereço da empresa para a seguinte conta:{""}{" "}
        <b>felipe.ecommercesmart@gmail.com</b>
      </S.PoliticaText>
    </S.PoliticaSections>
  );
};
