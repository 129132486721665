import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { ConteudoEcommerce } from "../components/ConteudoPlanos3";
import { Helmet } from "react-helmet";

export const SitesEcommerce = () => {
  return (
    <>
      <Helmet>
        <title>Consultoria especializada em e-commerce</title>
        <meta
          name="description"
          content="Consultoria especializada em e-commerce, oferecendo soluções completas para o sucesso online. Nossos serviços incluem Estratégias de E-commerce, Otimização de Conversão, Marketing Digital, Consultoria em Plataformas, Gestão de Catálogo, SEO, Marketing de Conteúdo, Design de Lojas Virtuais e muito mais. Conte conosco para impulsionar seu negócio online de forma eficaz e sustentável, alcançando resultados duradouros no mercado digital."
        />
      </Helmet>
      <Header2 />
      <ConteudoEcommerce />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
