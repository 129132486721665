import styled from "styled-components";

import {
  corPrimaria,
  corSecundaria,
  corTerciaria,
  temaPrimario,
} from "./GlobalStyles";
export const ContentSectionFaixa = styled.section`
  width: 100%;
  margin-top: 150px;
`;
export const ContentSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;

export const ContentTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${corSecundaria};
  margin-top: -150px;
  height: 350px;
  border: 10px solid;
  z-index: 2;
  border-image: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  border-image-slice: 1;
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 50px;
  }

  @media (min-width: 577px) and (max-width: 768px) {
    font-size: 0.8em;
    margin-top: 50px;
  }
  @media (max-width: 576px) {
    font-size: 0.65em;
    margin-top: 50px;
  }
`;
export const ContentLogoCard = styled.div`
  width: 30%;
  padding: 15px;
`;
export const ContentLogo = styled.img`
  width: 100%;
  background: ${temaPrimario};
`;

export const ContentTitleCard = styled.div`
  width: 70%;
`;
export const ContentTitle = styled.h2`
  font-size: 3rem;
  color: ${temaPrimario};
  padding: 10px;
  @media (min-width: 577px) and (max-width: 768px) {
    font-size: 2em;
  }
  @media (max-width: 576px) {
    font-size: 1.5em;
  }
`;
export const ContentText = styled.p`
  font-size: 1.3em;
  width: 85%;
  color: ${temaPrimario};
  padding: 10px;
`;
export const ContentCard = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
