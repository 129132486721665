import React from "react";
import * as S from "../styles/GoogleMaps";

export const MapaGoogle = () => {
  return (
    <S.GoogleMapsSections>
      <S.GoogleMaps
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.7695533341353!2d-46.56506050000002!3d-23.540789499999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5eed9d46a20f%3A0x1a2b5398c38d1e6c!2sR.%20Apucarana%2C%20272%20-%20Tatuap%C3%A9%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003311-000!5e0!3m2!1spt-BR!2sbr!4v1711653724147!5m2!1spt-BR!2sbr"
        title="Mapa do Google"
        width="100%"
        height="450"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></S.GoogleMaps>
      <S.ServicosDiv>
        <S.ServicosText>
          Estamos aqui para ajudar! Se surgir qualquer dúvida ou se precisar de
          assistência, por favor, não hesite em entrar em contato conosco. Nossa
          equipe está à disposição para oferecer todo o suporte necessário.
          <br />
          <br />
          Para nos encontrar: Rua Apucarana, 272, Tatuape São Paulo - SP.
        </S.ServicosText>
      </S.ServicosDiv>
    </S.GoogleMapsSections>
  );
};
