import styled from "styled-components";
import { corPrimaria, corSecundaria, temaPrimario } from "./GlobalStyles";
export const ButtonFixed = styled.button`
  position: fixed;
  bottom: 100px;
  right: 100px;
  padding: 20px;
  border: 2px solid ${corPrimaria};
  background: ${corSecundaria};
  cursor: pointer;
  z-index: 9999;
  @media (min-width: 993px) and (max-width: 1200px) {
    right: 20px;
    bottom: 70px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    right: 20px;
    bottom: 70px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    right: 20px;
    bottom: 70px;
  }

  @media (max-width: 576px) {
    right: 20px;
    bottom: 70px;
  }
`;
export const ButtonFixedContent = styled.div`
  position: relative;
  stroke-dasharray: 158;
  stroke-dashoffset: 158;
  stroke-width: 4px;
  stroke: #007bff;
  fill: none;
  transition: stroke-dashoffset 0.3s ease;
`;
export const ButtonFixedSpan = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 700px;
  transform: translate(-50%, -50%);
  color: ${temaPrimario};
  font-size: 16px;
  font-weight: bold;
`;
