import React, { useState } from "react";
import * as S from "../styles/MenuMobile";
import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

export const MenuMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showOpenButton, setShowOpenButton] = useState(true);

  const handleOpenDrawer = () => {
    onOpen();
    setShowOpenButton(false);
  };

  const handleCloseDrawer = () => {
    onClose();
    setShowOpenButton(true);
  };

  return (
    <S.SidebarSection>
      <S.SectionAsside>
        {showOpenButton && (
          <S.ButtonOpen
            icon={faBars}
            onClick={handleOpenDrawer}
            mt={4}
            mr={4}
          />
        )}

        <Drawer
          placement="top"
          onClose={handleCloseDrawer}
          isOpen={isOpen}
          size="sm"
        >
          <DrawerOverlay position="fixed" inset="0" />
          <DrawerContent>
            <S.AssideContent>
              <S.ButtonClosed icon={faTimes} onClick={handleCloseDrawer} />

              <S.TitleAsside>Menu</S.TitleAsside>
              <S.MenuContentList>
                <S.MenuContentItem to="/">Home</S.MenuContentItem>
                <S.MenuContentItem to="/servicos">Serviço</S.MenuContentItem>
                <S.MenuContentItem to="/servicos/planos/smart">
                  Smart Gerencia
                </S.MenuContentItem>
                <S.MenuContentItem to="/servicos/planos/sites">
                  Sites
                </S.MenuContentItem>
                <S.MenuContentItem to="/servicos/planos/logistica">
                  Logística
                </S.MenuContentItem>
                <S.MenuContentItem to="/servicos/planos/ecommerce">
                  E-commerce
                </S.MenuContentItem>

                <S.MenuContentItem to="/sobre">Sobre</S.MenuContentItem>
                <S.MenuContentItem to="/contato">Contato</S.MenuContentItem>
                <S.MenuContentItem to="/clientes">Clientes</S.MenuContentItem>
              </S.MenuContentList>
            </S.AssideContent>
          </DrawerContent>
        </Drawer>
      </S.SectionAsside>
    </S.SidebarSection>
  );
};
