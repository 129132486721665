import { useEffect, useState } from "react";

const useFraseDigitando = () => {
  const [fraseIndex, setFraseIndex] = useState(0);
  const [fraseDigitando, setFraseDigitando] = useState("");
  const [digitando, setDigitando] = useState(true); // Controla se está digitando ou apagando

  useEffect(() => {
    const frases = ["E-commerce", "Desenvolvimento de sites", "Logística"];

    const intervalId = setInterval(() => {
      setFraseDigitando((prevFrase) => {
        const fraseAtual = frases[fraseIndex];
        if (digitando) {
          // Adiciona uma letra à frase
          if (prevFrase.length < fraseAtual.length) {
            return fraseAtual.substring(0, prevFrase.length + 1);
          } else {
            // Quando a frase está totalmente digitada, muda para apagar após um atraso de 0.5s
            setTimeout(() => {
              setDigitando(false);
            }, 1000);
          }
        } else {
          // Remove uma letra da frase até que a frase esteja vazia
          if (prevFrase.length > 0) {
            return fraseAtual.substring(0, prevFrase.length - 1);
          } else {
            // Quando a frase foi totalmente apagada, muda para a próxima frase
            setFraseIndex((prevIndex) => (prevIndex + 1) % frases.length);
            setDigitando(true);
          }
        }
        // Retorna a frase atual se não houver alteração
        return prevFrase;
      });
    }, 100); // Velocidade de digitação em milissegundos

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, [fraseIndex, digitando]); // Dependências do useEffect

  return fraseDigitando;
};

export default useFraseDigitando;
