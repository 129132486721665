import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { ConteudoBasic } from "../components/ConteudoPlanos";
import { Helmet } from "react-helmet";

export const SitesBasicos = () => {
  return (
    <>
      <Helmet>
        <title>Criação de Sites Profissionais</title>
        <meta
          name="description"
          content="Criação de Sites Profissionais e Especialistas em Desenvolvimento Web. Oferecemos serviços completos, desde a criação de landing pages persuasivas até a implementação de lojas online com segurança e integração de pagamento. Nosso foco é proporcionar uma experiência excepcional ao usuário, com design responsivo, interface intuitiva e otimização para conversão de leads. Além disso, desenvolvemos sites institucionais robustos, destacando a história da empresa, sua missão e valores, equipe e parcerias. Conte conosco para impulsionar sua presença online, seja através de um site institucional corporativo, uma loja virtual de sucesso ou uma landing page eficaz para sua campanha de marketing."
        />
      </Helmet>
      <Header2 />
      <ConteudoBasic />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
