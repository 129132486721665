import axios from "axios";

const FORM_URL = "https://formspree.io/f/mleqkpee";

export const Email = async (data) => {
  try {
    const response = await axios.post(FORM_URL, data);
    return response.data;
  } catch (error) {
    console.error("Erro ao enviar os dados:", error);
    throw error;
  }
};
