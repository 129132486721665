import React from "react";
import * as S from "../styles/EcommerceStyles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PlanosCarrousel, SliderPlanos } from "../styles/PlanosStyles";

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const Ecommerce = () => {
  return (
    <>
      <SliderPlanos {...settings}>
        <PlanosCarrousel>
          <S.PlanosSectionCard>
            <S.PlanosSectionCardTitle>Basic</S.PlanosSectionCardTitle>
            <S.PlanosSectionCardSubTitle>
              Fundamental
            </S.PlanosSectionCardSubTitle>
            <S.PlanosSectionCardText>
              Perfeito para quem está começando no comércio eletrônico.
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardValue>
              <S.LinkPlanosValue
                to="https://api.whatsapp.com/send?phone=5511978549981"
                target="_blank"
                rel="noopener noreferrer"
              >
                Entre em contato
              </S.LinkPlanosValue>
            </S.PlanosSectionCardValue>
            <S.PlanosSectionCardText>Integração ERP</S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Criação de e-commerce customizada
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Integração com parceiros Marketplace
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardLink to="/servicos/planos/ecommerce">
              Ver Mais
            </S.PlanosSectionCardLink>
          </S.PlanosSectionCard>
        </PlanosCarrousel>
        <PlanosCarrousel>
          <S.PlanosSectionCard>
            <S.PlanosSectionCardTitle>Standard</S.PlanosSectionCardTitle>
            <S.PlanosSectionCardSubTitle>Elevate</S.PlanosSectionCardSubTitle>
            <S.PlanosSectionCardText>
              Ideal para empresas que buscam uma solução mais completa.
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardValue>
              <S.LinkPlanosValue
                to="https://api.whatsapp.com/send?phone=5511978549981"
                target="_blank"
                rel="noopener noreferrer"
              >
                Entre em contato
              </S.LinkPlanosValue>
            </S.PlanosSectionCardValue>
            <S.PlanosSectionCardText>
              Suporte Contratação ERP
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Integração com Marketplaces
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Integração Logistica para produtos até 30kg
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardLink to="/servicos/planos/ecommerce">
              Ver Mais
            </S.PlanosSectionCardLink>
          </S.PlanosSectionCard>
        </PlanosCarrousel>
        <PlanosCarrousel>
          <S.PlanosSectionCard>
            <S.PlanosSectionCardTitle>Premium</S.PlanosSectionCardTitle>
            <S.PlanosSectionCardSubTitle>Exclusivo</S.PlanosSectionCardSubTitle>
            <S.PlanosSectionCardText>
              Para empresas que necessitam de uma solução adaptada às suas
              necessidades específicas.
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardValue>
              <S.LinkPlanosValue
                to="https://api.whatsapp.com/send?phone=5511978549981"
                target="_blank"
                rel="noopener noreferrer"
              >
                Entre em contato
              </S.LinkPlanosValue>
            </S.PlanosSectionCardValue>
            <S.PlanosSectionCardText>
              Cadastro de até 100 Skus inicial
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Suporte para fechamento de contrato com Marketplace
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Suporte par Investimento em mídia
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardLink to="/servicos/planos/ecommerce">
              Ver Mais
            </S.PlanosSectionCardLink>
          </S.PlanosSectionCard>
        </PlanosCarrousel>
      </SliderPlanos>
    </>
  );
};
