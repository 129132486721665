import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as S from "../styles/ConteudoPlanos";
import { Collapse } from "bootstrap";

const AccordionItem = ({ title, content, index }) => {
  return (
    <S.AccordionItem className="accordion-item">
      <S.AccordionTitle className="accordion-header" id={`heading${index}`}>
        <S.AccordionButton
          className={`accordion-button${index === 0 ? "" : " collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${index}`}
          aria-expanded={index === 0 ? "true" : "false"}
          aria-controls={`collapse${index}`}
        >
          {title}
        </S.AccordionButton>
      </S.AccordionTitle>
      <S.AccordionColapse
        id={`collapse${index}`}
        className={`accordion-collapse collapse${index === 0 ? " show" : ""}`}
      >
        <S.AccordionBody className="accordion-body">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </S.AccordionBody>
      </S.AccordionColapse>
    </S.AccordionItem>
  );
};

export const ConteudoEcommerce = () => {
  useEffect(() => {
    const collapses = document.querySelectorAll(".accordion-collapse");
    collapses.forEach((collapse, index) => {
      if (index === 0) {
        new Collapse(collapse, { toggle: false }).show();
      } else {
        new Collapse(collapse, { toggle: false });
      }
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.BasicImg2 />
      <S.ConteudoSections>
        <S.ConteudoTitle2>Plano Basic</S.ConteudoTitle2>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Integração ERP"
            content="Integração com ERP é essencial para empresas modernas. Conecta sistemas de gestão empresarial a outras ferramentas, automatizando fluxos de trabalho e otimizando processos. Isso resulta em maior eficiência, precisão nos dados e melhor tomada de decisões."
            index={0}
          />
          <AccordionItem
            title="Criação de e-commerce customizada "
            content="
            Oferecemos criação de e-commerce customizada, desenvolvendo uma plataforma online única para atender às necessidades do seu negócio. Com design personalizado e integração de funcionalidades específicas, garantimos uma experiência de compra excepcional para seus clientes, impulsionando o crescimento da sua marca"
            index={1}
          />
          <AccordionItem
            title="Integração com parceiros Marketplace"
            content="
            Oferecemos integração com parceiros de marketplace para expandir a presença do seu negócio online. Com essa integração, você pode conectar sua loja a diversos marketplaces, alcançando um público maior e aumentando suas vendas. Simplificamos o processo, permitindo que você gerencie todas as suas vendas em um único lugar."
            index={2}
          />
          <AccordionItem
            title="Suporte a Integração Logistica"
            content="
            Oferecemos suporte completo à integração logística, garantindo uma conexão fluida entre sua loja online e os parceiros de transporte. Com nossa assistência, você pode automatizar o processo de envio, rastreamento de pedidos e gestão de estoque, otimizando sua operação e proporcionando uma experiência de entrega eficiente para seus clientes. Estamos aqui para garantir que sua integração logística seja perfeita e livre de problemas"
            index={3}
          />
        </S.Accordion>
      </S.ConteudoSections>
      <S.ConteudoSections>
        <S.ConteudoTitle2>Plano Standard</S.ConteudoTitle2>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Suporte Contratação ERP "
            content="Oferecemos suporte especializado para a contratação de sistemas ERP, ajudando sua empresa a escolher a solução mais adequada às suas necessidades. Desde a avaliação de opções até a negociação de contratos, nossa equipe está pronta para orientá-lo em cada etapa do processo. Conte conosco para garantir uma integração tranquila e eficiente do seu novo sistema ERP."
            index={4}
          />
          <AccordionItem
            title="Configuração Basica ERP "
            content="Oferecemos serviços de configuração básica de sistemas ERP, garantindo que sua plataforma seja ajustada de acordo com as necessidades específicas do seu negócio. Nossa equipe especializada cuidará da instalação, configuração inicial e personalização básica do seu ERP, proporcionando uma base sólida para otimizar seus processos empresariais. Confie em nós para iniciar sua jornada com o ERP de forma eficaz e sem complicações."
            index={5}
          />
          <AccordionItem
            title="Criação de e-commerce customizada "
            content="Oferecemos serviços de criação de e-commerce totalmente customizada, adaptada às necessidades exclusivas do seu negócio. Com uma abordagem personalizada, desenvolvemos uma plataforma de comércio eletrônico que reflete a identidade da sua marca, oferece uma experiência de compra única para seus clientes e impulsiona o crescimento do seu negócio online. Confie em nós para transformar sua visão em realidade no mundo digital."
            index={6}
          />
          <AccordionItem
            title="Cadastro de até 50 Skus inicial "
            content="Oferecemos o serviço de cadastro inicial de até 50 SKUs (Unidades de Manutenção de Estoque) para o seu e-commerce. Isso inclui a inserção de informações detalhadas sobre os produtos, como nome, descrição, preço e imagens, garantindo que sua loja online esteja pronta para começar a vender rapidamente. Deixe-nos cuidar do trabalho pesado enquanto você se concentra em expandir seus negócios"
            index={7}
          />
          <AccordionItem
            title="Integração Logistica para produtos até 30kg "
            content="
            Oferecemos integração logística especializada para produtos com peso de até 30kg. Isso inclui a conexão da sua loja online com parceiros de transporte para automação de processos de envio, rastreamento de pedidos e gestão de estoque. Com nossa solução de integração logística, você garante uma operação eficiente e uma experiência de entrega tranquila para seus clientes, independentemente do peso dos produtos vendidos."
            index={8}
          />
          <AccordionItem
            title="Integração com Parceiros Marketplaces "
            content="Oferecemos integração com parceiros de marketplace para expandir a presença do seu negócio online. Com essa integração, você pode conectar sua loja a diversos marketplaces, alcançando um público maior e aumentando suas vendas. Simplificamos o processo, permitindo que você gerencie todas as suas vendas em um único lugar"
            index={9}
          />
          <AccordionItem
            title="Integração com Marketplaces "
            content="Oferecemos configuração completa, incluindo integração com marketplace, para sua loja online. Isso envolve ajustes detalhados do seu sistema de e-commerce, integração com os principais marketplaces para ampliar seu alcance e maximizar suas vendas. Com nossa configuração total, você terá uma plataforma robusta e pronta para impulsionar o sucesso do seu negócio online. Deixe-nos cuidar dos detalhes técnicos enquanto você foca em fazer crescer sua operação."
            index={10}
          />
        </S.Accordion>
      </S.ConteudoSections>

      <S.ConteudoSections>
        <S.ConteudoTitle2>Plano Premium</S.ConteudoTitle2>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Todos os itens do Plano Basic e Standard"
            content={`Suporte Contratação ERP <br />
            Configuração Basica ERP <br />
            Criação de e-commerce customizada<br />
            Integração Logistica para produtos até 30kg<br />
            Suporte a Integração Logistica<br />
            Integração com Parceiros Marketplaces<br />
            Integração com Marketplaces<br />`}
            index={11}
          />
          <AccordionItem
            title="Integração Logistica para itens maior que 30kg"
            content="
            Oferecemos integração logística especializada para itens com peso superior a 30kg. Isso inclui a conexão da sua loja online com parceiros de transporte especializados em cargas pesadas, garantindo uma gestão eficiente de envios, rastreamento de pedidos e gerenciamento de estoque. Com nossa solução de integração logística, você pode oferecer uma experiência de entrega confiável e eficaz, independentemente do peso dos produtos comercializados em sua loja."
            index={12}
          />
          <AccordionItem
            title="Criação de link para produtos com peso maior que 30kg"
            content="Oferecemos serviços de criação de links personalizados para produtos com peso superior a 30kg. Esses links são adaptados para produtos mais pesados, garantindo uma experiência de compra fácil e direta para seus clientes. Com a criação desses links, você pode destacar seus produtos de carga pesada e facilitar o processo de compra, aumentando as vendas e a satisfação do cliente."
            index={13}
          />
          <AccordionItem
            title="Suporte para fechamento de contrato com Marketplace"
            content="Oferecemos suporte especializado para o fechamento de contratos com marketplaces. Nossa equipe está pronta para orientar e auxiliar sua empresa em todas as etapas desse processo, desde a negociação inicial até a assinatura do contrato. Com nossa experiência e conhecimento, garantimos que você obtenha os melhores termos e condições para maximizar sua presença e vendas nos marketplaces. Confie em nós para facilitar o caminho rumo ao sucesso do seu negócio online."
            index={14}
          />
          <AccordionItem
            title="Configuração total Com Marketplace"
            content="Oferecemos configuração completa, incluindo integração com marketplace, para sua loja online. Isso engloba ajustes detalhados do seu sistema de e-commerce, integração com os principais marketplaces para ampliar seu alcance e maximizar suas vendas. Com nossa configuração total, sua loja estará pronta para competir no mercado online de forma eficiente e bem-sucedida. Deixe-nos cuidar dos detalhes técnicos enquanto você foca em fazer crescer seu negócio."
            index={15}
          />
          <AccordionItem
            title="Cadastro de até 100 Skus inicial"
            content="Oferecemos o serviço de cadastro inicial de até 100 SKUs (Unidades de Manutenção de Estoque) para o seu e-commerce. Isso inclui a inserção de informações detalhadas sobre os produtos, como nome, descrição, preço e imagens, garantindo que sua loja online esteja pronta para começar a vender rapidamente. Deixe-nos cuidar do trabalho pesado enquanto você se concentra em expandir seus negócios."
            index={16}
          />
          <AccordionItem
            title="Suporte para Investimento em mídia "
            content="Oferecemos suporte especializado para investimentos em mídia, ajudando sua empresa a maximizar o retorno sobre o investimento (ROI) em campanhas publicitárias. Nossa equipe de especialistas em marketing digital está pronta para oferecer orientação estratégica, análise de dados e otimização de campanhas para garantir que seus investimentos em mídia sejam eficazes e alcancem seu público-alvo de forma eficiente. Com nosso suporte, você poderá aumentar a visibilidade da sua marca, gerar leads qualificados e impulsionar as vendas do seu e-commerce."
            index={17}
          />
        </S.Accordion>
      </S.ConteudoSections>
    </>
  );
};
