import styled from "styled-components";
import {
  corPrimaria,
  corSecundaria,
  corTerciaria,
  temaPrimario,
} from "./GlobalStyles";
import { Link } from "react-router-dom";

export const ServicosSection = styled.section`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-top: -100px;
`;
export const ServicosDiv = styled.div`
  background: ${corSecundaria};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-around;
  border: 10px solid;
  z-index: 2;
  border-image: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  border-image-slice: 1;
  @media (min-width: 769px) and (max-width: 992px) {
    flex-direction: column;
    padding: 20px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    padding: 20px;
  }
`;
export const ServicosIcon = styled.img`
  width: 60px;
  margin-left: 35px;
  transform: rotate(45deg);
`;
export const ServicosLink = styled(Link)`
  border: 2px solid ${temaPrimario};
  padding: 10px 20px;
  color: ${temaPrimario};
  transition: 0.5s ease-in-out;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    transition: 0.5s ease-in-out;
    background: ${temaPrimario};
    color: ${corSecundaria};
  }
`;
export const ServicosText = styled.p`
  margin: 30px;
  width: 65%;
  text-align: justify;
  font-size: 1.2em;
  color: ${temaPrimario};
  @media (max-width: 576px) {
    font-size: 1em;
  }
`;
export const ServicosDivContent = styled.div`
  margin: 80px auto;
  text-align: center;
`;
export const ServicosSubTitle = styled.h5`
  font-size: 1.5em;
  letter-spacing: 5px;
  color: ${corSecundaria};
`;
export const ServicosTitle = styled.h2`
  font-size: 3em;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: 576px) {
    font-size: 2rem;
    padding: 10px;
  }
`;

export const ServicosHr = styled.hr`
  width: 100px;
  border: 2px solid ${corPrimaria};
`;
export const ServicosText2 = styled.p`
  text-align: center;
  font-size: 22px;
  color: ${corTerciaria};
  @media (max-width: 576px) {
    font-size: 0.525em;
  }
`;
export const CardsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 40px;
  margin: 0 auto;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 60%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 60%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 576px) {
    width: 95%;
  }
`;
export const CardsDiv = styled.div`
  height: 600px;
  display: flex;
  background: ${temaPrimario};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px auto;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  &:hover {
    transition: 0.5s ease-in-out;
    transform: scale(1.1);
  }
  @media (max-width: 993px) {
    transform: none !important;
  }
`;
export const CardsDivImg = styled.img`
  width: 40%;
`;
export const CardsDivH3 = styled.h3`
  font-size: 2em;
`;
export const CardsDivP = styled.p`
  text-align: center;
  font-size: 1.125em;
  font-weight: 600;
  @media (max-width: 576px) {
    font-size: 1em;
    text-align: center;
    margin: 10px;
  }
`;
export const CardsDivLink = styled(Link)`
  cursor: pointer;
  margin-top: 100px;
  width: 50%;
  text-align: center;
  padding: 10px 20px;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  color: ${temaPrimario};
  transition: 0.5s ease-in-out;
  font-weight: 700;
  &:hover {
    border: 2px solid ${corSecundaria};
    transition: 0.5s ease-in-out;
    background: ${temaPrimario};
    color: ${corSecundaria};
  }
`;
export const CardsContentFaixa = styled.div`
  width: 100%;
  height: 1200px;
  background: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  margin-top: -350px;
`;
