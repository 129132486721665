import { useEffect } from "react";

export const ScrollListener = () => {
  useEffect(() => {
    const handleScroll = () => {
      let top = document.documentElement.scrollTop;
      if (top > 300) {
        document.getElementById("menu-fixo").classList.add("menuFixo");
      } else {
        document.getElementById("menu-fixo").classList.remove("menuFixo");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return null;
};
