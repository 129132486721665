import React, { useState } from "react";
import { Sites } from "../components/Sites";
import { Logistica } from "../components/Logistica";
import { Ecommerce } from "../components/Ecommerce";

export const Content = () => {
  const [activeContent, setActiveContent] = useState("Sites");
  const MudancaPort = (content) => {
    setActiveContent(content);
  };

  const Renderizar = () => {
    switch (activeContent) {
      case "Sites":
        return <Sites />;
      case "Logistica":
        return <Logistica />;
      case "Ecommerce":
        return <Ecommerce />;
      default:
        return null;
    }
  };

  return { MudancaPort, Renderizar };
};
