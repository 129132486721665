import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { Helmet } from "react-helmet";
import { NossosClientes } from "../components/NossosClientes";

export const Clientes = () => {
  return (
    <>
      <Helmet>
        <title>Nossos Clientes</title>
        <meta
          name="description"
          content="Nossos clientes se beneficiam de soluções personalizadas e inovadoras para suas necessidades de comércio eletrônico e logística. Oferecemos suporte abrangente, desde contratação de transportadoras até a criação de catálogos de produtos, garantindo excelência e eficiência em cada etapa."
        />
      </Helmet>
      <Header2 />
      <NossosClientes />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
