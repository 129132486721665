import React from "react";
import * as S from "../styles/LogisticaStyles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PlanosCarrousel, SliderPlanos } from "../styles/PlanosStyles";

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const Logistica = () => {
  return (
    <>
      <SliderPlanos {...settings}>
        <PlanosCarrousel>
          <S.PlanosSectionCard>
            <S.PlanosSectionCardTitle>Basic</S.PlanosSectionCardTitle>
            <S.PlanosSectionCardSubTitle>Essencial</S.PlanosSectionCardSubTitle>
            <S.PlanosSectionCardText>
              Uma solução simples e acessível de logística de vendas.
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardValue>
              A partir de <br />
              R$399,00/mensal
            </S.PlanosSectionCardValue>
            <S.PlanosSectionCardText>
              Integração com ERP
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Integração com Transportadoras de Pedido Pesados
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Link de Frete para Marketplace e/ou E-commerce
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardLink to="/servicos/planos/logistica">
              Ver Mais
            </S.PlanosSectionCardLink>
          </S.PlanosSectionCard>
        </PlanosCarrousel>
        <PlanosCarrousel>
          <S.PlanosSectionCard>
            <S.PlanosSectionCardTitle>Standard</S.PlanosSectionCardTitle>
            <S.PlanosSectionCardSubTitle>
              Aprimorado
            </S.PlanosSectionCardSubTitle>
            <S.PlanosSectionCardText>
              Ideal para empresas que buscam um serviço mais abrangente para
              aprimorar sua logística.
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardValue>
              {" "}
              A partir de <br />
              R$599,00/mensal
            </S.PlanosSectionCardValue>
            <S.PlanosSectionCardText>
              Todos os itens do Plano Basic
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Até 40mil cotações de tabela customizada
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Suporte via Whatsapp
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardLink to="/servicos/planos/logistica">
              Ver Mais
            </S.PlanosSectionCardLink>
          </S.PlanosSectionCard>
        </PlanosCarrousel>
        <PlanosCarrousel>
          <S.PlanosSectionCard>
            <S.PlanosSectionCardTitle>Premium</S.PlanosSectionCardTitle>
            <S.PlanosSectionCardSubTitle>
              Personalizado
            </S.PlanosSectionCardSubTitle>
            <S.PlanosSectionCardText>
              Para empresas que exigem uma solução personalizada e de alto
              nível.
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardValue>
              {" "}
              A partir de <br />
              R$1199,00/mensal
            </S.PlanosSectionCardValue>
            <S.PlanosSectionCardText>
              Todos os itens do Plano Basic e Standard
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Até 100mil cotações de tabela customizada
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardText>
              Suporte para Contratação de Transportadoras
            </S.PlanosSectionCardText>
            <S.PlanosSectionCardHr />
            <S.PlanosSectionCardLink to="/servicos/planos/logistica">
              Ver Mais
            </S.PlanosSectionCardLink>
          </S.PlanosSectionCard>
        </PlanosCarrousel>
      </SliderPlanos>
    </>
  );
};
