import React from "react";
import { Header2 } from "../components/Header2";
import { Rodape } from "../components/Rodape";
import { ErroAdmin } from "../components/404Adimin";

export const Admin = () => {
  return (
    <>
      <Header2 />
      <ErroAdmin />
      <Rodape />
    </>
  );
};
