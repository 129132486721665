import React, { useState } from "react";
import { Content } from "../context/ContentServicos";
import * as S from "../styles/PlanosServicosStyles";

export const PlanosServico = () => {
  const { MudancaPort, Renderizar } = Content();
  const [selectedButton, setSelectedButton] = useState("Smart");

  const handleClick = (content) => {
    MudancaPort(content);
    setSelectedButton(content);
  };
  return (
    <S.PlanosSection>
      <S.PlanosSectionTitle>
        Descubra Nossos Planos e Detalhes de Serviços
      </S.PlanosSectionTitle>

      <S.PlanosSectionText>
        Caso nao tenha encontrado o plano que procura entre em contato com o
        nosso time.
      </S.PlanosSectionText>
      <S.PlanosNav>
        <S.PlanosNavLink
          className={selectedButton === "Smart" ? "selecionado" : ""}
          onClick={() => handleClick("Smart")}
        >
          Smart Gerencia
        </S.PlanosNavLink>
        <S.PlanosNavLink
          className={selectedButton === "Sites" ? "selecionado" : ""}
          onClick={() => handleClick("Sites")}
        >
          Sites
        </S.PlanosNavLink>
        <S.PlanosNavLink
          className={selectedButton === "Logistica" ? "selecionado" : ""}
          onClick={() => handleClick("Logistica")}
        >
          Logistica
        </S.PlanosNavLink>
        <S.PlanosNavLink
          className={selectedButton === "Ecommerce" ? "selecionado" : ""}
          onClick={() => handleClick("Ecommerce")}
        >
          E-commerce
        </S.PlanosNavLink>
      </S.PlanosNav>
      <S.PlanosSectionContent>{Renderizar()}</S.PlanosSectionContent>
    </S.PlanosSection>
  );
};
