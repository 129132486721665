import styled from "styled-components";
import {
  corPrimaria,
  corSecundaria,
  corTerciaria,
  temaPrimario,
} from "./GlobalStyles";
import { Link } from "react-router-dom";

export const RodapeSection = styled.footer`
  width: 100%;
  height: auto;
  bottom: 0;
  background: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  color: ${temaPrimario};
`;
export const RodapeContent = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 40px;
  margin: 200px auto;
  padding-top: 50px;
  @media (min-width: 993px) and (max-width: 1200px) {
    grid-template-columns: 1fr;
    width: 90%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    grid-template-columns: 1fr;
    width: 90%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 576px) {
    font-size: 90%;
  }
`;
export const RodapeContentDiv = styled.div``;
export const RodapeLogo = styled.img`
  width: 30%;
  background: ${temaPrimario};
  margin-top: -150px;
  border: 10px solid;
  border-image: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  border-image-slice: 1;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 20%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 20%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 20%;
  }
  @media (max-width: 576px) {
    width: 20%;
  }
`;

export const RodapeTitle = styled.h2`
  margin-left: 100px;
  font-weight: 700;
  @media (min-width: 993px) and (max-width: 1200px) {
    margin-left: -1px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    margin-left: -1px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    margin-left: -1px;
    padding: 10px;
  }
  @media (max-width: 576px) {
    margin-left: -1px;
    padding: 10px;
  }
`;
export const RodapeText = styled.p`
  font-size: 1.125em;
  font-weight: 700;
`;
export const RodapeNav = styled.nav`
  display: flex;
  flex-direction: column;
`;

export const RodapeLink = styled(Link)`
  margin-left: 100px;
  padding: 1%;
  font-size: 1.125em;
  font-weight: 700;
  color: ${temaPrimario};
  cursor: pointer;
  transition: 0.7s ease-in-out;
  &:hover {
    color: ${corPrimaria};
    filter: brightness(1.8);
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    margin-left: -1px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    margin-left: -1px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    margin-left: -1px;
    padding: 10px;
  }
  @media (max-width: 576px) {
    margin-left: -1px;
    padding: 10px;
  }
`;
export const RodapeDivision = styled.div`
  height: 5px;
  width: 100%;
  margin-top: -160px;
  background: ${corPrimaria};
`;
export const RodapeDivisionContente = styled.div`
  height: 55px;
  width: 100%;
  margin-top: -160px;
  background: ${corTerciaria};
`;
export const RodapeDivisionContentetext = styled.div`
  max-width: 1200px;

  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const RodapeDivisionContentetextp = styled.p``;
