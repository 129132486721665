import React from "react";
import Logo from "../images/logo.png";
import * as S from "../styles/RodapeStyles";

export const Rodape = () => {
  return (
    <S.RodapeSection>
      <S.RodapeContent>
        <S.RodapeContentDiv>
          <S.RodapeLogo src={Logo} alt="Logo Ecommerce Smart" />
          <S.RodapeTitle style={{ marginLeft: "initial" }}>
            E-commerce Smart
          </S.RodapeTitle>
          <S.RodapeText>
            Nossa missão e levar você ao topo por meio do marketing digital!
            Somos referência em atendimento e desenvolvimento criativo.
          </S.RodapeText>
        </S.RodapeContentDiv>
        <S.RodapeContentDiv>
          <S.RodapeTitle>Navegação</S.RodapeTitle>
          <S.RodapeNav>
            <S.RodapeLink to="/">Home</S.RodapeLink>
            <S.RodapeLink to="/servicos">Serviços</S.RodapeLink>
            <S.RodapeLink to="/sobre">Sobre</S.RodapeLink>
            <S.RodapeLink to="/contato">Contato</S.RodapeLink>
          </S.RodapeNav>
        </S.RodapeContentDiv>
        <S.RodapeContentDiv>
          <S.RodapeTitle>Contato</S.RodapeTitle>
          <S.RodapeNav>
            <S.RodapeLink to="/contato">Falar Conosco</S.RodapeLink>
            <S.RodapeLink to="/contato">Solicitar Orçamento</S.RodapeLink>
            <S.RodapeLink to="/politica">Política de Privacidade</S.RodapeLink>
            <S.RodapeLink to="/contato">
              felipe.ecommercesmart@gmail.com
            </S.RodapeLink>
          </S.RodapeNav>
        </S.RodapeContentDiv>
      </S.RodapeContent>
      <S.RodapeDivisionContente>
        <S.RodapeDivisionContentetext>
          <S.RodapeDivisionContentetextp>
            Copyright © 2017-2024 | E-Commerce Smart CNPJ:28.484.195/0001-01 |
            All Rights Reserved.
          </S.RodapeDivisionContentetextp>
        </S.RodapeDivisionContentetext>
      </S.RodapeDivisionContente>
    </S.RodapeSection>
  );
};
