import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { MapaGoogle } from "../components/GoogleMaps";
import { Contato } from "../components/Contato";
import { Helmet } from "react-helmet";
export const Contatos = () => {
  return (
    <>
      <Helmet>
        <title>Contato</title>
        <meta
          name="description"
          content="Entre em contato conosco na E-Commerce Smart para obter suporte personalizado e respostas rápidas para suas dúvidas. Nossa equipe está aqui para ajudar a impulsionar o sucesso do seu negócio online. Envie-nos uma mensagem hoje mesmo e dê o primeiro passo rumo ao crescimento no comércio eletrônico!"
        />
      </Helmet>
      <Header2 />
      <BotaoFixo />
      <MapaGoogle />
      <Contato />
      <Rodape />
    </>
  );
};
