import React from "react";
import { Header2 } from "../components/Header2";
import { Rodape } from "../components/Rodape";
import { ErroLogin } from "../components/404Login";

export const Login = () => {
  return (
    <>
      <Header2 />
      <ErroLogin />
      <Rodape />
    </>
  );
};
