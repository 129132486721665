import React from "react";
import Erro from "../images/erro.svg";
import { ErroImg, ErroSection } from "../styles/ErroStyles";

export const ErroAdmin = () => {
  return (
    <ErroSection>
      <ErroImg src={Erro} alt="Erro Pagina nao encontrada" />
    </ErroSection>
  );
};
