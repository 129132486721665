import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as S from "../styles/ClientesStyles";
import Americanas from "../images/americanas.png";
import Amazon from "../images/amazon.png";
import Casa from "../images/casa.png";
import Maga from "../images/magazine.png";
import Net from "../images/netshoes.png";
import ViaVarejo from "../images/via.png";
import Zattini from "../images/zattini.png";
export const Clientes = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <S.ClientesSection>
      <S.ClientesSubTitle>Clientes</S.ClientesSubTitle>
      <S.ClientesTitle>Sua loja no lugar certo.</S.ClientesTitle>

      <S.ClientesSlider {...settings}>
        <S.ClientesImg src={Americanas} alt="Americanas" />
        <S.ClientesImg src={Net} alt="Netshoes" />
        <S.ClientesImg src={Zattini} alt="Zattini" />
        <S.ClientesImg src={Maga} alt="Magazine Luiza" />
        <S.ClientesImg src={Amazon} alt=" Amazon" />
        <S.ClientesImg src={ViaVarejo} alt="Via Varejo" />
        <S.ClientesImg src={Casa} alt="Casas Bahia" />
        <S.ClientesImg src={Americanas} alt="Americanas" />
        <S.ClientesImg src={Net} alt="Netshoes" />
        <S.ClientesImg src={Zattini} alt="Zattini" />
        <S.ClientesImg src={Maga} alt="Magazine Luiza" />
        <S.ClientesImg src={Amazon} alt=" Amazon" />
        <S.ClientesImg src={ViaVarejo} alt="Via Varejo" />
        <S.ClientesImg src={Casa} alt="Casas Bahia" />
      </S.ClientesSlider>
    </S.ClientesSection>
  );
};
