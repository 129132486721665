import styled from "styled-components";
import {
  corPrimaria,
  corSecundaria,
  corTerciaria,
  temaPrimario,
} from "./GlobalStyles";
import { Link } from "react-router-dom";
export const DiferencialSections = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  text-align: start;
`;
export const DiferencialSubTitle = styled.h5`
  text-align: center;
  font-size: 1.5em;
  letter-spacing: 5px;
  color: ${corSecundaria};
  @media (max-width: 576px) {
    font-size: 1.2em;
    padding: 10px;
  }
`;
export const DiferencialTitle = styled.h2`
  text-align: center;
  font-size: 3em;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: 576px) {
    font-size: 2rem;
    padding: 10px;
  }
`;
export const DiferencialHr = styled.hr`
  width: 100px;
  border: 2px solid ${corPrimaria};
`;
export const DiferencialContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 40px;
  margin: 80px auto;
  @media (min-width: 993px) and (max-width: 1200px) {
    grid-template-columns: 1fr;
    width: 45%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    grid-template-columns: 1fr;
    width: 55%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
`;
export const DiferencialCard = styled.div`
  margin: 10px auto;
  padding: 20px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  border: 10px solid;
  border-image: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  border-image-slice: 1;
`;
export const DiferencialCardImg = styled.img`
  width: 20%;
`;
export const DiferencialCardTitle = styled.h3`
  font-size: 1.5em;
  color: ${corSecundaria};
`;
export const DiferencialCardText = styled.p`
  text-align: justify;
  font-weight: 600;
  font-size: 1.125em;
  color: ${corTerciaria};
`;
export const DiferencialCardLink = styled(Link)`
  cursor: pointer;
  margin-top: 100px;
  width: 50%;
  text-align: center;
  padding: 10px 20px;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  color: ${temaPrimario};
  transition: 0.5s ease-in-out;
  font-weight: 700;
  &:hover {
    transition: 0.5s ease-in-out;
    border: 2px solid ${corSecundaria};
    background: ${temaPrimario};
    color: ${corTerciaria};
  }
`;
