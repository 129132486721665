import React from "react";
import * as S from "../styles/PlanosStyles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const Sites = () => {
  return (
    <S.SliderPlanos {...settings}>
      <S.PlanosCarrousel>
        <S.PlanosSectionCard>
          <S.PlanosSectionCardTitle>
            Basic Landing Page
          </S.PlanosSectionCardTitle>
          <S.PlanosSectionCardSubTitle>
            Saindo na frente
          </S.PlanosSectionCardSubTitle>
          <S.PlanosSectionCardText>
            Para você que quer começar com o pé direito no meio digital e se
            diferenciar no mercado.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardValue>
            <S.LinkPlanos
              to="https://api.whatsapp.com/send?phone=5511978549981"
              target="_blank"
              rel="noopener noreferrer"
            >
              Entre em Contato
            </S.LinkPlanos>
          </S.PlanosSectionCardValue>
          <S.PlanosSectionCardText>
            Criação de um site Landing Page.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardText>
            Otimização para Motores de Busca (SEO).
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardText>Manutenção básica.</S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardLink to="/servicos/planos/sites">
            Ver Mais
          </S.PlanosSectionCardLink>
        </S.PlanosSectionCard>
      </S.PlanosCarrousel>
      <S.PlanosCarrousel>
        <S.PlanosSectionCard>
          <S.PlanosSectionCardTitle>
            Basic Institucional
          </S.PlanosSectionCardTitle>
          <S.PlanosSectionCardSubTitle>
            Elevando o Padrão
          </S.PlanosSectionCardSubTitle>
          <S.PlanosSectionCardText>
            Potencialize sua Presença Online e Destaque-se no Cenário
            Empresarial.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardValue>
            <S.LinkPlanos
              to="https://api.whatsapp.com/send?phone=5511978549981"
              target="_blank"
              rel="noopener noreferrer"
            >
              Entre em Contato
            </S.LinkPlanos>
          </S.PlanosSectionCardValue>
          <S.PlanosSectionCardText>
            Criação de um site institucional.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardText>Hospedagem inclusa.</S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardText>
            E-mail personalizado.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardLink to="/servicos/planos/sites">
            Ver Mais
          </S.PlanosSectionCardLink>
        </S.PlanosSectionCard>
      </S.PlanosCarrousel>
      <S.PlanosCarrousel>
        <S.PlanosSectionCard>
          <S.PlanosSectionCardTitle>Standard</S.PlanosSectionCardTitle>
          <S.PlanosSectionCardSubTitle>
            Expandindo sua Presença
          </S.PlanosSectionCardSubTitle>
          <S.PlanosSectionCardText>
            Para aqueles que procuram estabelecer uma forte presença digital e
            se destacar no mercado.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardValue>
            <S.LinkPlanos
              to="https://api.whatsapp.com/send?phone=5511978549981"
              target="_blank"
              rel="noopener noreferrer"
            >
              Entre em Contato
            </S.LinkPlanos>
          </S.PlanosSectionCardValue>
          <S.PlanosSectionCardText>
            Site Dinâmico com gerenciamento de conteúdo.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardText>
            Suporte técnico estendido.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardText>
            Manutenção contínua do site.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardLink to="/servicos/planos/sites">
            Ver Mais
          </S.PlanosSectionCardLink>
        </S.PlanosSectionCard>
      </S.PlanosCarrousel>
      <S.PlanosCarrousel>
        <S.PlanosSectionCard>
          <S.PlanosSectionCardTitle>Premium</S.PlanosSectionCardTitle>
          <S.PlanosSectionCardSubTitle>
            Dominando o Digital
          </S.PlanosSectionCardSubTitle>
          <S.PlanosSectionCardText>
            Para quem busca uma presença online incomparável e deseja dominar o
            ambiente digital.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardValue>
            <S.LinkPlanos
              to="https://api.whatsapp.com/send?phone=5511978549981"
              target="_blank"
              rel="noopener noreferrer"
            >
              Entre em Contato
            </S.LinkPlanos>
          </S.PlanosSectionCardValue>
          <S.PlanosSectionCardText>
            Desenvolvimento de E-commerce completo.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardText>
            Integração com sistemas de pagamento.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardText>
            Suporte prioritário e manutenção avançada.
          </S.PlanosSectionCardText>
          <S.PlanosSectionCardHr />
          <S.PlanosSectionCardLink to="/servicos/planos/sites">
            Ver Mais
          </S.PlanosSectionCardLink>
        </S.PlanosSectionCard>
      </S.PlanosCarrousel>
    </S.SliderPlanos>
  );
};
