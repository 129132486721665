import React from "react";
import * as S from "../styles/NossosClientes";
import LogoB4B from "../images/B4F-logo-svg.png";
import LogoFc from "../images/logofc.jpg";
import LogoSb from "../images/Santo-Box.svg";
import LogoS from "../images/SBB.png";
import { Link } from "react-router-dom";

const clientes = [
  {
    id: "1",
    logo: LogoB4B,
    nome: "B4B COMERCIAL E SERVICOS LTDA",
    fantasia: "B4B Solutions",
    descricao:
      "A B4B Comercial é especializada no comércio de produtos eletrônicos, oferecendo soluções de alta qualidade e inovação para seus clientes. Com vasta experiência no atendimento de licitações, a empresa se destaca pela a eficiência e compromisso em fornecer os melhores produtos e serviços.",
    link: "https://b4bsolutions.com.br/",
    programa: "Smart Gerencia",
  },
  {
    id: "2",
    logo: LogoSb,
    nome: "SANTO BOX COMERCIO DE COLCHOES LTDA",
    fantasia: "Santo Box",
    descricao:
      "A Santo Box é um fabricante de moveis focado em qualidade de atendimento ao cliente, em desenvolvimento com a E-commerce Smart, expandimos sua atuação para o D2C, levando a qualidade de seus produtos e agilidade de atendimento diretamente ao consumidor, proporcionando preços mais competitivos, produtos de qualidade e excelência no atendimento.",
    link: "https://www.santobox.com.br/",
    programa: "Smart Gerencia",
  },
  {
    id: "3",
    logo: LogoS,
    nome: "SB BOX COMERCIO DE MOVEIS LTDA",
    fantasia: "SB Box Comercial",
    descricao:
      "A Sb Box Comercial é especializada em móveis, sempre prezando pelo meio ambiente com o uso de madeiras de reflorestamento. Com a premissa de colocar o cliente no centro do negócio, oferecemos produtos de alta qualidade e sustentabilidade, garantindo satisfação e responsabilidade ambiental.",
    link: "",
    programa: "Smart Gerencia",
  },
  {
    id: "4",
    logo: LogoFc,
    fantasia: "FC Comercial",
    nome: "FC IMPORTADORA E DISTRIBUIDORA LTDA",
    descricao:
      "A FC Importadora e Distribuidora Ltda é especializada na importação e distribuição de produtos tecnológicos inovadores. Com um portfólio diversificado e foco em qualidade, oferecemos soluções avançadas que atendem às demandas do mercado, garantindo eficiência e satisfação aos nossos clientes.",
    link: "",
    programa: "Smart Gerencia",
  },
];

export const NossosClientes = () => {
  return (
    <S.ContentClientes>
      <S.BasicImg3 />
      <S.NossosClientesTitle>Nossos Clientes</S.NossosClientesTitle>
      <div className="container mt-5">
        <div className="row">
          {clientes.map((cliente, index) => (
            <div key={index} className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="row g-0 h-100">
                  <S.ContentImagem className="col-md-4">
                    <S.ImagemCard
                      src={cliente.logo}
                      className="img-fluid rounded-start"
                      alt={cliente.nome}
                    />
                  </S.ContentImagem>
                  <div className="col-md-8">
                    <div className="card-body d-flex flex-column justify-content-between">
                      <div>
                        <h5 className="card-title">{cliente.nome}</h5>
                        <h6 className="card-title">{cliente.fantasia}</h6>
                        <p className="card-text">{cliente.descricao}</p>
                      </div>
                      <p className="card-text">
                        <Link
                          className="text-body-primary"
                          to={cliente.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {cliente.link}
                        </Link>
                      </p>
                      <h6>
                        Programa:{" "}
                        <S.LinkSmart to="/servicos/planos/smart">
                          {cliente.programa}
                        </S.LinkSmart>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </S.ContentClientes>
  );
};
