import styled from "styled-components";
import {
  corPrimaria,
  corSecundaria,
  corTerciaria,
  temaPrimario,
} from "./GlobalStyles";
import { Link } from "react-router-dom";
export const ContatoTotal = styled.section`
  width: 100%;
  margin: 100px auto;
`;
export const ContatoSection = styled.div`
  max-width: 1200px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  color: ${corSecundaria};
  @media (min-width: 993px) and (max-width: 1200px) {
    flex-direction: column;
    gap: 40px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    flex-direction: column;
    gap: 40px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    gap: 40px;
  }
`;
export const ContatoContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 80%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 80%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
`;
export const ContatoSubTitle = styled.h5`
  font-size: 1.2em;
  letter-spacing: 7px;
`;
export const ContatoTitle = styled.h2`
  font-size: 2em;
`;
export const ContatoContentIcon = styled.div`
  display: flex;
  margin: 50px;
`;
export const ContatoImg = styled.img`
  width: 5%;
  height: 5%;
`;
export const ContatoText = styled.p`
  text-align: justify;
  font-weight: 600;
  font-size: 1.125em;
  margin-left: 20px;
  @media (max-width: 576px) {
    font-size: 1em;
  }
`;

export const ContatoLink = styled(Link)`
  cursor: pointer;
  margin-left: 6%;
  width: 50%;
  font-size: 1.125em;
  text-align: center;
  padding: 10px 20px;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  color: ${temaPrimario};
  transition: 0.5s ease-in-out;
  font-weight: 700;
  &:hover {
    font-weight: 700;
    border: 2px solid ${corSecundaria};
    transition: 0.5s ease-in-out;
    background: ${temaPrimario};
    color: ${corSecundaria};
    overflow: hidden;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

export const ContatoFormContent = styled.div`
  width: 75%;
  border: 10px solid;
  border-image: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  border-image-slice: 1;
  @media (min-width: 577px) and (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
export const ContatoFormDiv = styled.div`
  padding: 10px;
`;
export const ContatoForm = styled.form``;
export const ContatoInput = styled.input`
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  border-bottom: 2px solid ${corTerciaria};
  color: ${corSecundaria};
  font-weight: 600;
  font-size: 1.1em;
  &:focus {
    outline: none;
    color: ${corSecundaria};
    font-weight: 600;
    font-size: 1.3em;
  }
  &::placeholder {
    color: ${corSecundaria};
    font-weight: 600;
    font-size: 1.1em;
  }
`;
export const ContatoTextArea = styled.textarea`
  width: 100%;
  height: 350px;
  resize: none;
  background: transparent;
  border: none;
  border-bottom: 2px solid ${corTerciaria};
  color: ${corSecundaria};
  font-weight: 600;
  font-size: 1.1em;
  &:focus {
    outline: none;
    color: ${corSecundaria};
    font-weight: 600;
    font-size: 1.3em;
  }
  &::placeholder {
    color: ${corSecundaria};
    font-weight: 600;
    font-size: 1.1em;
  }
`;
export const ContatoButton = styled.button`
  padding: 10px 30px;
  font-weight: 700;
  font-size: 1.125em;
  color: ${temaPrimario};
  border: none;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  border: 1px solid ${corSecundaria};
  transition: 0.5s ease-in-out;
  &:hover {
    background: ${temaPrimario};
    color: ${corSecundaria};
  }
  @media (max-width: 576px) {
    font-size: 1em;
  }
`;

export const EmailContato = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: green;
`;
export const EmailContatoErro = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: red;
`;
