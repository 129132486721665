import React, { useState, useEffect } from "react";
import * as S from "../styles/BotaoFixoStyles"; // Importe os estilos corretamente
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export const BotaoFixo = () => {
  const [scrollPercent, setScrollPercent] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // Atualize a porcentagem de scroll
      setScrollPercent(scrollTop);

      // Atualize a visibilidade do botão
      setIsVisible(scrollTop > 600); // Botão aparece após rolar 600px
    };

    window.addEventListener("scroll", handleScroll);

    // Remova o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Função para lidar com o clique no botão
  const handleClick = () => {
    // Role a página até o topo
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Rolagem suave
    });
  };

  return (
    <S.ButtonFixed
      onClick={handleClick}
      style={{
        display: isVisible ? "block" : "none",
        transition: "opacity 2s ease-in-out",
      }}
    >
      <S.ButtonFixedContent
        style={{ strokeDashoffset: `calc(158 - (${scrollPercent} * 1.58))` }}
      ></S.ButtonFixedContent>
      <S.ButtonFixedSpan>
        <FontAwesomeIcon icon={faArrowUp} />
      </S.ButtonFixedSpan>
    </S.ButtonFixed>
  );
};
