import styled from "styled-components";
import {
  corSecundaria,
  corPrimaria,
  corTerciaria,
  temaPrimario,
} from "./GlobalStyles";
import Slider from "react-slick";
export const ClientesSection = styled.section`
  width: 100%;
  height: auto;
  margin: 150px auto;
  text-align: center;
  background: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
`;
export const ClientesSubTitle = styled.h5`
  font-size: 1.4em;
  letter-spacing: 5px;
  font-weight: 700;
  padding: 50px;
  color: ${temaPrimario};
`;
export const ClientesTitle = styled.h2`
  font-size: 2.5em;
  color: ${temaPrimario};
  margin-top: -50px;
  @media (max-width: 576px) {
    font-size: 2rem;
    padding: 10px;
  }
`;
export const ClientesHr = styled.hr`
  width: 100px;
  border: 2px solid ${temaPrimario};
  margin-bottom: 40px;
`;
export const ClientesSlider = styled(Slider)`
  overflow: hidden;
`;
export const ClientesImg = styled.img`
  width: 200px;
  background: ${temaPrimario};
  aspect-ratio: 1/1;
  object-fit: contain;
  mix-blend-mode: color-burn;
`;
