import React from "react";
import Saiba from "../videos/Smart.mp4";
import Insta from "../images/insta.png";
import Whats from "../images/whatsapp.png";
import Email from "../images/email.png";
import * as S from "../styles/SaibaMaisStyles";
export const SaibaMais = () => {
  return (
    <S.SaibaMaisSection>
      <S.SaibaMaisDiv>
        <S.SaibaMaisTitle>
          Saiba mais sobre a <br /> E-commerce Smart
        </S.SaibaMaisTitle>
        <S.SaibaMaisText>
          Na E-commerce Smart, nossa missão é impulsionar o sucesso de nossos
          clientes no mundo do comércio eletrônico. Estamos comprometidos em
          fornecer soluções inovadoras e personalizadas que capacitam as
          empresas a prosperarem no ambiente digital em constante evolução.
          Acreditamos que cada cliente é único, e nosso objetivo é fornecer uma
          experiência excepcional, desde o desenvolvimento de lojas online até a
          implementação de estratégias de marketing digital. Com uma abordagem
          centrada no cliente e uma equipe apaixonada por tecnologia e inovação,
          estamos aqui para ajudar nossos clientes a alcançarem seus objetivos e
          se destacarem em um mercado competitivo. Junte-se a nós na jornada
          rumo ao sucesso no comércio eletrônico com a E-commerce Smart.
        </S.SaibaMaisText>
        <S.SaibaMaisContato>
          <S.SaibaMaisImg src={Insta} alt="Instagran" />
          <S.SaibaMaisIcon>@e_commercesmart</S.SaibaMaisIcon>

          <S.SaibaMaisImg
            src={Whats}
            alt="Whatsapp"
            to="https://api.whatsapp.com/send?phone=5511961237632"
            target="_blank"
            rel="noopener noreferrer"
          />

          <S.SaibaMaisIcon
            to="https://api.whatsapp.com/send?phone=5511961237632"
            target="_blank"
            rel="noopener noreferrer"
          >
            (11) 961237632
          </S.SaibaMaisIcon>
          <S.SaibaMaisImg src={Email} alt="Email" />
          <S.SaibaMaisIcon>felipe.ecommercesmart@gmail.com</S.SaibaMaisIcon>
        </S.SaibaMaisContato>
      </S.SaibaMaisDiv>
      <S.SaibaMaisVideoDiv>
        <S.SaibaMaisVideo src={Saiba} autoPlay loop muted alt="Logistica" />
      </S.SaibaMaisVideoDiv>
    </S.SaibaMaisSection>
  );
};
