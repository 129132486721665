import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as S from "../styles/ConteudoPlanos";
import { Collapse } from "bootstrap";

const AccordionItem = ({ title, content, index }) => {
  return (
    <S.AccordionItem className="accordion-item">
      <S.AccordionTitle className="accordion-header" id={`heading${index}`}>
        <S.AccordionButton
          className={`accordion-button${index === 0 ? "" : " collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${index}`}
          aria-expanded={index === 0 ? "true" : "false"}
          aria-controls={`collapse${index}`}
        >
          {title}
        </S.AccordionButton>
      </S.AccordionTitle>
      <S.AccordionColapse
        id={`collapse${index}`}
        className={`accordion-collapse collapse${index === 0 ? " show" : ""}`}
      >
        <S.AccordionBody className="accordion-body">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </S.AccordionBody>
      </S.AccordionColapse>
    </S.AccordionItem>
  );
};

export const ConteudoBasic = () => {
  useEffect(() => {
    const collapses = document.querySelectorAll(".accordion-collapse");
    collapses.forEach((collapse, index) => {
      if (index === 0) {
        new Collapse(collapse, { toggle: false }).show();
      } else {
        new Collapse(collapse, { toggle: false });
      }
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.BasicImg />
      <S.ConteudoSections>
        <S.ConteudoTitle>Plano Basic Landing Page</S.ConteudoTitle>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Criação de Landing Page"
            content="Criação de uma Landing Page que se destaca, projetada para converter visitantes em clientes. Com um design atrativo e foco na usabilidade, sua Landing Page será otimizada para gerar leads e impulsionar as conversões. Destaque os benefícios do seu produto ou serviço, promova ofertas especiais e incentive a ação dos visitantes. Maximize o retorno sobre seu investimento com uma Landing Page de alta qualidade, desenvolvida sob medida para o seu negócio!"
            index={0}
          />
          <AccordionItem
            title="Responsividade"
            content="Garanta uma Experiência Impecável em Todos os Dispositivos com Responsividade! Oferecemos o desenvolvimento de sites e aplicativos responsivos, que se adaptam perfeitamente a qualquer tamanho de tela e dispositivo. Nossa equipe especializada utiliza as mais recentes técnicas e tecnologias para garantir que seu conteúdo seja exibido de forma clara e intuitiva em smartphones, tablets, laptops e desktops. Com a responsividade garantida, você pode alcançar e envolver seus clientes em qualquer lugar e a qualquer momento, proporcionando uma experiência de usuário consistente e satisfatória. Conte conosco para criar uma presença online que se destaque, independentemente do dispositivo utilizado pelos seus usuários."
            index={1}
          />
          <AccordionItem
            title="Domínio Próprio"
            content="Oferecemos a exclusividade de um domínio personalizado para o seu site. Transmita confiança, profissionalismo e tenha controle total sobre sua marca online. Invista na identidade única do seu site e impulsione sua presença na web hoje mesmo"
            index={2}
          />
          <AccordionItem
            title="Otimização para Motores de Busca (SEO)"
            content="Oferecemos Otimização para Motores de Busca para os seus sites, impulsionando sua visibilidade online. Apareça nas primeiras posições dos resultados de busca, aumente o tráfego qualificado e conquiste mais clientes. Invista na estratégia certa para o sucesso do seu negócio online com nossa oferta de SEO!."
            index={3}
          />
          <AccordionItem
            title="Manutenção Básica"
            content="Manutenção Básica para seus sites, garantindo que estejam sempre funcionando sem problemas. Atualizações regulares, correções de bugs e suporte técnico garantem a estabilidade e segurança do seu site. Deixe-nos cuidar da manutenção para que você possa focar no crescimento do seu negócio online!"
            index={4}
          />
          <AccordionItem
            title="Suporte Técnico "
            content="Suporte Técnico especializado para seu site, pronto para resolver qualquer problema ou dúvida que você possa ter. Conte com nossa equipe experiente para orientação, solução de problemas e suporte técnico contínuo. Mantenha seu site funcionando sem interrupções e alcance seus objetivos online com confiança"
            index={5}
          />
          <AccordionItem
            title=" E-mail personalizado "
            content="Conta de E-mail Personalizada para o seu site, proporcionando uma identidade profissional única. Tenha um endereço de e-mail exclusivo que reflita a sua marca, aumentando a credibilidade e confiança dos seus clientes. Simplifique a comunicação e fortaleça sua presença online com uma conta de e-mail personalizada hoje mesmo!"
            index={6}
          />
        </S.Accordion>
      </S.ConteudoSections>
      <S.ConteudoSections>
        <S.ConteudoTitle>Plano Basic Institutional</S.ConteudoTitle>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Criação de Site Institucional"
            content="Criação de um Site Institucional com 5 a 10 páginas, customizado para atender às necessidades únicas do seu negócio. Transmita sua mensagem de forma clara e cativante, com um design profissional e responsivo. Apresente seus produtos, serviços e valores de maneira envolvente, conquistando a confiança dos visitantes. Invista na sua presença online e impulsione seu sucesso com nosso serviço de criação de site institucional!"
            index={7}
          />
          <AccordionItem
            title="Responsividade"
            content="Garanta uma Experiência Impecável em Todos os Dispositivos com Responsividade! Oferecemos o desenvolvimento de sites e aplicativos responsivos, que se adaptam perfeitamente a qualquer tamanho de tela e dispositivo. Nossa equipe especializada utiliza as mais recentes técnicas e tecnologias para garantir que seu conteúdo seja exibido de forma clara e intuitiva em smartphones, tablets, laptops e desktops. Com a responsividade garantida, você pode alcançar e envolver seus clientes em qualquer lugar e a qualquer momento, proporcionando uma experiência de usuário consistente e satisfatória. Conte conosco para criar uma presença online que se destaque, independentemente do dispositivo utilizado pelos seus usuários."
            index={8}
          />
          <AccordionItem
            title="Domínio Próprio"
            content="Oferecemos a exclusividade de um domínio personalizado para o seu site. Transmita confiança, profissionalismo e tenha controle total sobre sua marca online. Invista na identidade única do seu site e impulsione sua presença na web hoje mesmo"
            index={9}
          />
          <AccordionItem
            title="Otimização para Motores de Busca (SEO)"
            content="Oferecemos Otimização para Motores de Busca para os seus sites, impulsionando sua visibilidade online. Apareça nas primeiras posições dos resultados de busca, aumente o tráfego qualificado e conquiste mais clientes. Invista na estratégia certa para o sucesso do seu negócio online com nossa oferta de SEO!."
            index={10}
          />
          <AccordionItem
            title="Manutenção Básica"
            content="Manutenção Básica para seus sites, garantindo que estejam sempre funcionando sem problemas. Atualizações regulares, correções de bugs e suporte técnico garantem a estabilidade e segurança do seu site. Deixe-nos cuidar da manutenção para que você possa focar no crescimento do seu negócio online!"
            index={11}
          />
          <AccordionItem
            title="Suporte Técnico "
            content="Suporte Técnico especializado para seu site, pronto para resolver qualquer problema ou dúvida que você possa ter. Conte com nossa equipe experiente para orientação, solução de problemas e suporte técnico contínuo. Mantenha seu site funcionando sem interrupções e alcance seus objetivos online com confiança"
            index={12}
          />
          <AccordionItem
            title=" E-mail personalizado "
            content="Conta de E-mail Personalizada para o seu site, proporcionando uma identidade profissional única. Tenha um endereço de e-mail exclusivo que reflita a sua marca, aumentando a credibilidade e confiança dos seus clientes. Simplifique a comunicação e fortaleça sua presença online com uma conta de e-mail personalizada hoje mesmo!"
            index={13}
          />
        </S.Accordion>
      </S.ConteudoSections>
      <S.ConteudoSections>
        <S.ConteudoTitle>Plano Standard</S.ConteudoTitle>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Site Dinâmico"
            content="Criação de Sites Dinâmicos utilizando frameworks líderes de mercado, como Django, Laravel, Strapi e Express. Com nossa expertise em desenvolvimento web, construiremos um site que não apenas impressionará visualmente, mas também oferecerá desempenho excepcional e funcionalidades avançadas. Desde a criação de uma arquitetura sólida até a implementação de recursos interativos, nosso objetivo é fornecer uma solução sob medida que atenda às suas necessidades específicas. Alcance novos patamares de excelência online com nossa equipe especializada em desenvolvimento web!T"
            index={14}
          />
          <AccordionItem
            title="Responsividade"
            content="Garanta uma Experiência Impecável em Todos os Dispositivos com Responsividade! Oferecemos o desenvolvimento de sites e aplicativos responsivos, que se adaptam perfeitamente a qualquer tamanho de tela e dispositivo. Nossa equipe especializada utiliza as mais recentes técnicas e tecnologias para garantir que seu conteúdo seja exibido de forma clara e intuitiva em smartphones, tablets, laptops e desktops. Com a responsividade garantida, você pode alcançar e envolver seus clientes em qualquer lugar e a qualquer momento, proporcionando uma experiência de usuário consistente e satisfatória. Conte conosco para criar uma presença online que se destaque, independentemente do dispositivo utilizado pelos seus usuários."
            index={15}
          />
          <AccordionItem
            title="Suporte técnico estendido"
            content="Serviço de suporte técnico diferenciado, indo além das expectativas para garantir o máximo de satisfação e tranquilidade para nossos clientes. Com nossa equipe altamente qualificada e dedicada, você terá acesso a assistência rápida e eficaz sempre que precisar. Estendemos nosso compromisso para além do básico, fornecendo soluções personalizadas e orientações especializadas para garantir o pleno funcionamento e sucesso contínuo do seu projeto. Conte conosco para superar desafios e alcançar seus objetivos com confiança e segurança!"
            index={16}
          />
          <AccordionItem
            title="Domínio Próprio"
            content="Oferecemos a exclusividade de um domínio personalizado para o seu site. Transmita confiança, profissionalismo e tenha controle total sobre sua marca online. Invista na identidade única do seu site e impulsione sua presença na web hoje mesmo"
            index={17}
          />
          <AccordionItem
            title="Otimização para Motores de Busca (SEO)"
            content="Oferecemos Otimização para Motores de Busca para os seus sites, impulsionando sua visibilidade online. Apareça nas primeiras posições dos resultados de busca, aumente o tráfego qualificado e conquiste mais clientes. Invista na estratégia certa para o sucesso do seu negócio online com nossa oferta de SEO!."
            index={18}
          />
          <AccordionItem
            title=" E-mail personalizado "
            content="Conta de E-mail Personalizada para o seu site, proporcionando uma identidade profissional única. Tenha um endereço de e-mail exclusivo que reflita a sua marca, aumentando a credibilidade e confiança dos seus clientes. Simplifique a comunicação e fortaleça sua presença online com uma conta de e-mail personalizada hoje mesmo!"
            index={19}
          />
          <AccordionItem
            title="Segurança do seu Site"
            content=" Segurança do seu Site com Nossa Manutenção Contínua! Oferecemos um serviço abrangente de manutenção contínua para o seu site, que inclui atualizações de segurança, correções de bugs e melhorias regulares. Com nossa equipe especializada cuidando do seu site, você pode ficar tranquilo sabendo que ele estará sempre protegido contra ameaças online e funcionando sem problemas. Além disso, estamos sempre buscando maneiras de aprimorar e otimizar o desempenho do seu site para garantir a melhor experiência possível para seus visitantes. Confie em nós para manter seu site seguro, atualizado e em pleno funcionamento enquanto você se concentra em fazer seu negócio crescer!"
            index={20}
          />
        </S.Accordion>
      </S.ConteudoSections>

      <S.ConteudoSections>
        <S.ConteudoTitle>Plano Premium</S.ConteudoTitle>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Desenvolvimento de E-commerce completo"
            content="Serviço completo de desenvolvimento de e-commerce, desde a concepção até a implementação e além. Nossa equipe experiente trabalhará em estreita colaboração com você para entender suas necessidades e objetivos, criando uma plataforma de comércio eletrônico que se destaque da concorrência. Com recursos avançados de segurança, design responsivo e funcionalidades personalizadas, garantimos uma experiência de compra excepcional para seus clientes. Além disso, oferecemos suporte contínuo para garantir que seu e-commerce esteja sempre atualizado e funcionando sem problemas. Maximize suas vendas online e impulsione seu sucesso com nosso serviço de desenvolvimento de e-commerce completo!"
            index={21}
          />
          <AccordionItem
            title="Integração com sistemas de pagamento
            "
            content="Facilite as Transações Online com Nossa Integração de Sistemas de Pagamento! Oferecemos uma integração perfeita com os principais sistemas de pagamento para o seu e-commerce. Desde gateways de pagamento tradicionais até soluções modernas de fintech, garantimos que seus clientes tenham uma experiência de compra conveniente e segura. Nossa equipe cuidará de todos os aspectos técnicos da integração, permitindo que você aceite uma variedade de métodos de pagamento de forma eficiente. Com uma integração sólida e confiável, você poderá expandir seu alcance e aumentar suas vendas online. Deixe-nos simplificar o processo de pagamento para você e seus clientes, enquanto você se concentra em fazer seu negócio prosperar!"
            index={22}
          />
          <AccordionItem
            title="Suporte prioritário e manutenção avançada"
            content="Priorize a Excelência com Nosso Suporte Prioritário e Manutenção Avançada! Oferecemos um serviço exclusivo de suporte prioritário e manutenção avançada para garantir que seu site ou aplicativo esteja sempre no seu melhor estado. Como parte deste serviço premium, você terá acesso direto a nossa equipe dedicada de especialistas, prontos para resolver qualquer problema com rapidez e eficiência. Além disso, nossa manutenção avançada inclui monitoramento proativo, atualizações contínuas de segurança e melhorias regulares para garantir a estabilidade, segurança e desempenho do seu sistema. Conte conosco para fornecer o mais alto nível de suporte e manutenção, permitindo que você se concentre no crescimento e sucesso do seu negócio."
            index={23}
          />
          <AccordionItem
            title="Responsividade"
            content="Garanta uma Experiência Impecável em Todos os Dispositivos com Responsividade! Oferecemos o desenvolvimento de sites e aplicativos responsivos, que se adaptam perfeitamente a qualquer tamanho de tela e dispositivo. Nossa equipe especializada utiliza as mais recentes técnicas e tecnologias para garantir que seu conteúdo seja exibido de forma clara e intuitiva em smartphones, tablets, laptops e desktops. Com a responsividade garantida, você pode alcançar e envolver seus clientes em qualquer lugar e a qualquer momento, proporcionando uma experiência de usuário consistente e satisfatória. Conte conosco para criar uma presença online que se destaque, independentemente do dispositivo utilizado pelos seus usuários."
            index={24}
          />
          <AccordionItem
            title="Domínio Próprio"
            content="Oferecemos a exclusividade de um domínio personalizado para o seu site. Transmita confiança, profissionalismo e tenha controle total sobre sua marca online. Invista na identidade única do seu site e impulsione sua presença na web hoje mesmo"
            index={25}
          />
          <AccordionItem
            title="Otimização para Motores de Busca (SEO)"
            content="Oferecemos Otimização para Motores de Busca para os seus sites, impulsionando sua visibilidade online. Apareça nas primeiras posições dos resultados de busca, aumente o tráfego qualificado e conquiste mais clientes. Invista na estratégia certa para o sucesso do seu negócio online com nossa oferta de SEO!."
            index={25}
          />
          <AccordionItem
            title=" E-mail personalizado "
            content="Conta de E-mail Personalizada para o seu site, proporcionando uma identidade profissional única. Tenha um endereço de e-mail exclusivo que reflita a sua marca, aumentando a credibilidade e confiança dos seus clientes. Simplifique a comunicação e fortaleça sua presença online com uma conta de e-mail personalizada hoje mesmo!"
            index={27}
          />
        </S.Accordion>
      </S.ConteudoSections>
    </>
  );
};
