import React from "react";
import { Header } from "../components/Header";
import { Servicos } from "../components/Sevicos";
import { Planos } from "../components/Planos";
import { SaibaMais } from "../components/SaibaMais";
import { Clientes } from "../components/Clientes";
import { Diferencial } from "../components/Diferencial";
import { Contato } from "../components/Contato";
import { Rodape } from "../components/Rodape";
import { BotaoFixo } from "../context/BotaoFixo";
import { Helmet } from "react-helmet";

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>E-commerce Smart</title>
        <meta
          name="description"
          content="Consultoria de E-commerce oferecendo soluções abrangentes para o sucesso online. Especializamo-nos em Estratégias de E-commerce, Otimização de Conversão, Marketing para Lojas Virtuais e Consultoria em Plataformas de E-commerce. Também fornecemos serviços como Gestão de Catálogo Online, SEO, Marketing de Conteúdo, Design de Lojas Virtuais e muito mais. Conte conosco para ajudar a impulsionar o seu negócio online de forma eficaz e sustentável."
        />
      </Helmet>
      <Header />
      <Servicos />
      <Planos />
      <SaibaMais />
      <Clientes />
      <Diferencial />
      <Contato />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
