import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalStyles } from "./styles/GlobalStyles";
import { Home } from "./pages/PageHome";
import { Services } from "./pages/PageServices";
import { Sobre } from "./pages/PageSobre";
import { Contatos } from "./pages/PageContato";
import { SitesBasicos } from "./pages/PagePlanosSites";
import { SitesEcommerce } from "./pages/PagePlanosEcommerce";
import { SitesLogistica } from "./pages/PagePlanosLogistica";
import { Politica } from "./pages/PagePolitica";
import { Login } from "./pages/PageLogin";
import { Admin } from "./pages/PageAdmin";
import { Clientes } from "./pages/PageClientes";
import { SitesSmart } from "./pages/PagePlanosSmart";

// import { Acesso } from "./pages/PageAcesso";
// import { Gerencia } from "./pages/PageGerencia";

export const App = () => {
  return (
    <Router>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/servicos" element={<Services />} />
        <Route path="/servicos/planos/smart" element={<SitesSmart />} />
        <Route path="/servicos/planos/sites" element={<SitesBasicos />} />
        <Route path="/servicos/planos/logistica" element={<SitesLogistica />} />
        <Route path="/servicos/planos/ecommerce" element={<SitesEcommerce />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/contato" element={<Contatos />} />
        <Route path="/politica" element={<Politica />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/clientes" element={<Clientes />} />

        {/* <Route path="/acesso" element={<Acesso />} />
        <Route path="/gerencia" element={<Gerencia />} /> */}
      </Routes>
    </Router>
  );
};
