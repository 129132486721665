import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as S from "../styles/ConteudoPlanos";
import { Collapse } from "bootstrap";

const AccordionItem = ({ title, content, index }) => {
  return (
    <S.AccordionItem className="accordion-item">
      <S.AccordionTitle className="accordion-header" id={`heading${index}`}>
        <S.AccordionButton
          className={`accordion-button${index === 0 ? "" : " collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${index}`}
          aria-expanded={index === 0 ? "true" : "false"}
          aria-controls={`collapse${index}`}
        >
          {title}
        </S.AccordionButton>
      </S.AccordionTitle>
      <S.AccordionColapse
        id={`collapse${index}`}
        className={`accordion-collapse collapse${index === 0 ? " show" : ""}`}
      >
        <S.AccordionBody className="accordion-body">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </S.AccordionBody>
      </S.AccordionColapse>
    </S.AccordionItem>
  );
};

export const ConteudoLogistica = () => {
  useEffect(() => {
    const collapses = document.querySelectorAll(".accordion-collapse");
    collapses.forEach((collapse, index) => {
      if (index === 0) {
        new Collapse(collapse, { toggle: false }).show();
      } else {
        new Collapse(collapse, { toggle: false });
      }
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.BasicImg1 />
      <S.ConteudoSections>
        <S.ConteudoTitle1>Plano Basic</S.ConteudoTitle1>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Integração com ERP "
            content="Integração com ERP é essencial para empresas modernas. Conecta sistemas de gestão empresarial a outras ferramentas, automatizando fluxos de trabalho e otimizando processos. Isso resulta em maior eficiência, precisão nos dados e melhor tomada de decisões."
            index={0}
          />
          <AccordionItem
            title="Link de Frete para Marketplace e/ou E-commerce"
            content="Link de Frete para Marketplace e/ou E-commerce simplifica a logística de vendas online. Ao integrar-se aos sistemas de comércio eletrônico, permite que vendedores ofereçam opções de frete diretamente aos clientes, facilitando a gestão de envios e proporcionando uma experiência de compra mais transparente e conveniente"
            index={1}
          />
          <AccordionItem
            title="Integração com Transportadoras de Pedido Leve"
            content="
            A integração com transportadoras de pedido leve agiliza o processo de entrega para empresas que lidam com produtos de baixo peso. Ao conectar-se diretamente aos sistemas de transporte, permite uma gestão eficiente dos pedidos, otimizando rotas de entrega e garantindo uma experiência rápida e econômica para os clientes."
            index={2}
          />
          <AccordionItem
            title="Rastreamento Automatico para transportadoras homologadas"
            content="
            O rastreamento automático para transportadoras homologadas oferece aos clientes uma experiência de entrega transparente e conveniente. Ao integrar-se aos sistemas de transporte aprovados, permite que os clientes acompanhem o status de seus pedidos em tempo real, proporcionando confiança e tranquilidade durante todo o processo de entrega. "
            index={3}
          />
          <AccordionItem
            title=" 1 Tabela de frete customizada para produtos pesados"
            content="
            Uma tabela de frete customizada para produtos pesados é essencial para garantir uma logística eficiente e econômica. Através dessa personalização, as empresas podem ajustar as tarifas de transporte de acordo com o peso dos produtos, otimizando custos e oferecendo opções de entrega competitivas aos clientes. "
            index={4}
          />
          <AccordionItem
            title="Até 20mil cotações de tabela customizada sem custo adicional"
            content="
            Oferecemos até 20 mil cotações de tabela customizada sem custo adicional, permitindo que as empresas otimizem sua logística de forma econômica e eficiente. Essa ampla capacidade de cotação personalizada proporciona flexibilidade e controle sobre os custos de transporte, garantindo uma gestão de frete sob medida para as necessidades específicas de cada empresa. "
            index={5}
          />
          <AccordionItem
            title="Suporte por e-mail "
            content="
            Oferecemos suporte por e-mail para garantir uma comunicação ágil e eficaz com nossos clientes. Nosso time está pronto para responder às suas dúvidas, fornecer assistência técnica e resolver quaisquer problemas que possam surgir, garantindo uma experiência de atendimento personalizada e satisfatória."
            index={6}
          />
        </S.Accordion>
      </S.ConteudoSections>
      <S.ConteudoSections>
        <S.ConteudoTitle1>Plano Standard</S.ConteudoTitle1>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Todos os itens do Plano Basic"
            content={`Integração com ERP<br />
    Link de Frete para Marketplace e/ou E-commerce<br />
    Integração com Transportadoras de Pedido Leve<br />
    Rastreamento Automatico para transportadoras homologadas<br />
   
    Suporte por e-mail<br />`}
            index={7}
          />

          <AccordionItem
            title="3 Tabelas de frete customizada para produtos pesados"
            content="
            Oferecemos até 3 tabelas de frete customizadas para produtos pesados, permitindo que sua empresa tenha flexibilidade na gestão logística e no cálculo de custos de transporte. Essas tabelas adaptadas às necessidades específicas de seus produtos pesados ajudarão a otimizar sua cadeia de suprimentos e garantir uma entrega eficiente e econômica para seus clientes."
            index={8}
          />
          <AccordionItem
            title="Até 40mil cotações de tabela customizada sem custo adicional"
            content="Oferecemos a vantagem de até 40 mil cotações de tabela customizada sem custo adicional. Isso proporciona à sua empresa uma ampla margem para adaptar as tarifas de frete conforme necessário, garantindo uma gestão logística eficiente e econômica. Essa generosa oferta permite que você atenda às demandas variáveis do mercado e mantenha seus custos de transporte sob controle, proporcionando uma experiência positiva aos seus clientes."
            index={9}
          />
          <AccordionItem
            title="Suporte via Whatsapp "
            content="Oferecemos suporte via WhatsApp para garantir uma comunicação rápida e conveniente com nossos clientes. Nosso time está disponível para responder suas dúvidas, fornecer assistência técnica e resolver qualquer problema que possa surgir, oferecendo uma experiência de atendimento ágil e personalizada diretamente na palma da sua mão."
            index={10}
          />
        </S.Accordion>
      </S.ConteudoSections>

      <S.ConteudoSections>
        <S.ConteudoTitle1>Plano Premium</S.ConteudoTitle1>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Todos os itens do Plano Basic e Standard"
            content={`Integração com ERP<br />
            Link de Frete para Marketplace e/ou E-commerce<br />
            Integração com Transportadoras de Pedido Leve<br />
            Rastreamento Automatico para transportadoras homologadas<br />
           
            Suporte por e-mail<br />
            Suporte via Whatsapp`}
            index={11}
          />
          <AccordionItem
            title="5 Tabelas de frete customizada para produtos pesados"
            content="Oferecemos a personalização de até 5 tabelas de frete para produtos pesados, adaptadas às necessidades específicas da sua empresa. Essa flexibilidade permite ajustar as tarifas de transporte de acordo com diferentes variáveis, garantindo uma gestão logística eficiente e otimizada para seus produtos de maior peso. Essas tabelas customizadas ajudarão a garantir uma entrega eficiente e econômica para seus clientes, mantendo seus custos sob controle."
            index={12}
          />
          <AccordionItem
            title="Atualização de Tabela de Frete Gratuita Ilimitada"
            content="Oferecemos atualizações ilimitadas da tabela de frete gratuitamente. Isso garante que sua empresa tenha sempre as tarifas de frete mais atualizadas e precisas, adaptadas às mudanças do mercado e às necessidades logísticas em constante evolução. Essa oferta ilimitada permite que você mantenha sua operação logística eficiente e competitiva, proporcionando uma experiência de entrega confiável e satisfatória para seus clientes."
            index={13}
          />
          <AccordionItem
            title="Até 100mil cotações de tabela customizada sem custo adicional"
            content="Oferecemos a conveniência de até 100 mil cotações de tabela customizada sem custo adicional. Isso proporciona à sua empresa uma ampla flexibilidade para ajustar e personalizar as tarifas de frete de acordo com as necessidades do seu negócio e as demandas do mercado. Essa generosa oferta garante uma gestão logística eficiente e econômica, permitindo que você atenda às exigências variáveis do seu setor e mantenha seus custos de transporte sob controle."
            index={14}
          />
          <AccordionItem
            title="Consultoria e Suporte para Contratação de Transportadoras"
            content="Oferecemos consultoria e suporte especializado para a contratação de transportadoras, auxiliando sua empresa na escolha das melhores opções de transporte de acordo com suas necessidades específicas. Nossa equipe está disponível para fornecer orientação sobre as melhores práticas, avaliar as opções disponíveis no mercado e negociar contratos vantajosos em seu nome. Conte conosco para garantir uma cadeia logística eficiente e confiável para o seu negócio."
            index={15}
          />
        </S.Accordion>
      </S.ConteudoSections>
    </>
  );
};
