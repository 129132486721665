import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { PlanosServico } from "../components/PlanosServicos";
import { Helmet } from "react-helmet";

export const Services = () => {
  return (
    <>
      <Helmet>
        <title>Serviços</title>
        <meta
          name="description"
          content="Especialista em E-commerce e Criação de Sites Profissionais. Oferecemos soluções abrangentes para o sucesso online, desde o desenvolvimento e gestão de lojas virtuais eficientes até a criação de sites institucionais e landing pages persuasivas. Conte com nossa expertise em E-commerce, UI/UX Design, SEO, Marketing de Conteúdo, Logística de E-commerce e muito mais para impulsionar sua presença digital e alcançar resultados excepcionais no mercado online."
        />
      </Helmet>
      <Header2 />
      <PlanosServico />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
