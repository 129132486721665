import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {
  corPrimaria,
  corSecundaria,
  corTerciaria,
  temaPrimario,
} from "../styles/GlobalStyles";
import { Link } from "react-router-dom";

export const SidebarSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  position: fixed;
  top: 50px;
  left: 20px;
  width: 100%;
  z-index: 999999;
  display: none;

  @media (min-width: 769px) and (max-width: 992px) {
    display: block;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    display: block;
  }
  @media (max-width: 576px) {
    display: block;
  }
`;
export const SectionAsside = styled.header`
  height: 100%;
  position: fixed;
  display: flex;
`;
export const TitleAssidePage = styled.h2``;
export const AssideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: auto;
  background: rgba(74, 94, 124, 0.5);
  width: 100%;
  height: auto;
  border: none;
  box-shadow: 0 4px 8px ${corPrimaria};
`;
export const ButtonOpen = styled(FontAwesomeIcon)`
  font-size: 2rem;
  color: ${corTerciaria};
  margin-top: -20px;
`;
export const ButtonClosed = styled(FontAwesomeIcon)`
  align-self: flex-start;
  font-size: 2em;
  margin-left: 40px;
  margin-top: 20px;
  color: ${temaPrimario};
  z-index: 1;
`;
export const TitleAsside = styled.h2`
  font-size: 2em;
  font-weight: 700;
  color: ${temaPrimario};
  text-align: center;
`;

// Introducao do menu

export const MenuContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const MenuContentList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: -20px;
`;

export const MenuContentItem = styled(Link)`
  cursor: pointer;
  font-weight: 700;
  color: ${temaPrimario};
  font-size: 1.4em;
  padding: 10px 20px;
  text-align: start;
  &:hover {
    transition: 0.5s ease-in-out;
    color: ${corSecundaria};
  }
`;
