import React, { useState } from "react";
import Alvo from "../images/alvo.png";
import Qualidade from "../images/quali.png";
import Insta from "../images/int.png";
import * as S from "../styles/ContatoStyles";
import { Email } from "../functions/email";

export const Contato = () => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    mensagem: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Email(formData);
      setSubmitted(true);
      setFormData({
        nome: "",
        email: "",
        telefone: "",
        mensagem: "",
      });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <S.ContatoTotal>
      <S.ContatoSection>
        <S.ContatoContent>
          <S.ContatoSubTitle>Vamos Conversar?</S.ContatoSubTitle>
          <S.ContatoTitle>
            Estamos preparados para um novo desafio
          </S.ContatoTitle>
          <S.ContatoContentIcon>
            <S.ContatoImg src={Alvo} alt="Vamos Conversar" />
            <S.ContatoText>Somos para todos os negócios</S.ContatoText>
          </S.ContatoContentIcon>
          <S.ContatoContentIcon>
            <S.ContatoImg
              style={{ width: "6%", marginLeft: "-7px" }}
              src={Qualidade}
              alt="Todos os negócios"
            />
            <S.ContatoText>Qualidade e Custo Benefício</S.ContatoText>
          </S.ContatoContentIcon>
          <S.ContatoContentIcon>
            <S.ContatoImg src={Insta} alt="Instagram" />

            <S.ContatoText>Siga também @e_commercesmart</S.ContatoText>
          </S.ContatoContentIcon>

          <S.ContatoLink
            to="https://api.whatsapp.com/send?phone=5511961237632"
            target="_blank"
            rel="noopener noreferrer"
          >
            Faça seu atendimento mais rápido via WhatsApp
          </S.ContatoLink>
        </S.ContatoContent>
        <S.ContatoFormContent>
          <S.ContatoForm onSubmit={handleSubmit}>
            <S.ContatoFormDiv>
              <S.ContatoInput
                placeholder="Nome"
                type="text"
                id="nome"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
              />
            </S.ContatoFormDiv>
            <S.ContatoFormDiv>
              <S.ContatoInput
                placeholder="Telefone"
                type="tel"
                id="telefone"
                name="telefone"
                value={formData.telefone}
                onChange={handleChange}
              />
            </S.ContatoFormDiv>
            <S.ContatoFormDiv>
              <S.ContatoInput
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </S.ContatoFormDiv>
            <S.ContatoFormDiv>
              <S.ContatoTextArea
                name="mensagem"
                id="mensagem"
                cols={30}
                rows={10}
                placeholder="Informe sua mensagem"
                value={formData.mensagem}
                onChange={handleChange}
              />
            </S.ContatoFormDiv>
            <S.ContatoFormDiv>
              <S.ContatoButton type="submit">Enviar</S.ContatoButton>
            </S.ContatoFormDiv>
          </S.ContatoForm>
          {submitted && (
            <S.EmailContato>Email enviado com sucesso!</S.EmailContato>
          )}
          {error && (
            <S.EmailContatoErro>
              Erro ao enviar o Email: {error.message}
            </S.EmailContatoErro>
          )}
        </S.ContatoFormContent>
      </S.ContatoSection>
    </S.ContatoTotal>
  );
};
