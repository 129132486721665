import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as S from "../styles/ConteudoPlanos";
import { Collapse } from "bootstrap";

const AccordionItem = ({ title, content, index }) => {
  return (
    <S.AccordionItem className="accordion-item">
      <S.AccordionTitle className="accordion-header" id={`heading${index}`}>
        <S.AccordionButton
          className={`accordion-button${index === 0 ? "" : " collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${index}`}
          aria-expanded={index === 0 ? "true" : "false"}
          aria-controls={`collapse${index}`}
        >
          {title}
        </S.AccordionButton>
      </S.AccordionTitle>
      <S.AccordionColapse
        id={`collapse${index}`}
        className={`accordion-collapse collapse${index === 0 ? " show" : ""}`}
      >
        <S.AccordionBody className="accordion-body">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </S.AccordionBody>
      </S.AccordionColapse>
    </S.AccordionItem>
  );
};

export const ConteudoSmart = () => {
  useEffect(() => {
    const collapses = document.querySelectorAll(".accordion-collapse");
    collapses.forEach((collapse, index) => {
      if (index === 0) {
        new Collapse(collapse, { toggle: false }).show();
      } else {
        new Collapse(collapse, { toggle: false });
      }
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <S.BasicImg3 />
      <S.ConteudoSections>
        <S.ConteudoTitle>Smart Gerencia</S.ConteudoTitle>
        <S.Accordion className="accordion">
          <AccordionItem
            title="Logística e Transporte"
            content="# Suporte e contratação de transportadoras.<br /># Criação e adequação de tabelas de frete.<br /># Link de frete para integração com parceiros Marketplace e e-commerces."
            index={0}
          />

          <AccordionItem
            title="Suporte Fiscal
            "
            content="# Suporte a tributação de produtos para compra e venda.<br/>
            # Configuração fiscal de ERP.
            
            "
            index={1}
          />
          <AccordionItem
            title="Catálogos e Produtos
            "
            content="# Desenvolvimento de Catálogo de produtos.<br/>
            # Criação de EAN.<br/>
            # Criação e/ou edição de fotos para adequação ao canal digital.<br/>
            # Suporte a criação de novos produtos com base em aceitação de mercado e nivelamento de preço.



            "
            index={2}
          />
          <AccordionItem
            title="Desenvolvimento de Linha de crédito nacional"
            content="# Cadastro com as principais industriais e distribuidoras nacionais.<br/>
            # Levantamento de documentação e liberação de crédito inicial com fornecedores de acordo com a categoria de interesse.</br>
            # Manutenção de crédito, negociação de prazos de pagamento e produtos.<br/>
            # Compras de mercadoria para revenda, negociação prazos e valores de acordo com a necessidade do lojista e mercado. "
            index={3}
          />
          <AccordionItem
            title="Gestão de Canais"
            content="# Negociação e criação de contrato com parceiros.<br/>
            # Manutenção e desenvolvimento de redes sociais."
            index={4}
          />
        </S.Accordion>
      </S.ConteudoSections>
    </>
  );
};
