import styled from "styled-components";
import { corSecundaria, corPrimaria } from "./GlobalStyles";
export const PlanosSection = styled.section`
  max-width: 1200px;
  width: 100%;
  margin: 100px auto;
  text-align: center;

  color: ${corSecundaria};
`;
export const PlanosSectionH5 = styled.h5`
  font-size: 1.4em;
  letter-spacing: 5px;
`;
export const PlanosSectionTitle = styled.h2`
  font-size: 3em;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: 576px) {
    font-size: 2rem;
    padding: 10px;
  }
`;
export const PlanosSectionHr = styled.hr`
  width: 100px;
  border: 2px solid ${corPrimaria};
  margin-top: -15px;
`;
export const PlanosSectionText = styled.p`
  text-align: center;
  font-size: 22px;
  @media (max-width: 576px) {
    font-size: 1.3em;
    padding: 10px;
  }
`;
export const PlanosSectionContent = styled.div`
  max-width: 1200px;
  width: 100%;
  text-align: justify;
`;

export const PlanosNav = styled.nav`
  margin: 50px auto;
  font-size: 1.5em;
  width: 60%;
  @media (min-width: 577px) and (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
export const PlanosNavLink = styled.a`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 10px;
  color: ${corSecundaria};
  cursor: pointer;
  transition: 0.7s ease-in-out;
  &:hover {
    color: ${corPrimaria};
    filter: brightness(1.5);
  }
  &.selecionado {
    color: ${corPrimaria};
    border-bottom: 2px solid ${corPrimaria};
    filter: brightness(1.5);
  }
  @media (max-width: 576px) {
    font-size: 1rem;
  }
`;
