import React, { useState } from "react";
import { ConteudoBasic } from "../components/ConteudoPlanos";
import { ConteudoLogistica } from "../components/ConteudoPlanos2";
import { ConteudoEcommerce } from "../components/ConteudoPlanos3";
import { ConteudoSmart } from "../components/ConteudoPlanos4";

export const Content = () => {
  const [activeContent, setActiveContent] = useState("Smart");
  const MudancaPort = (content) => {
    setActiveContent(content);
  };

  const Renderizar = () => {
    switch (activeContent) {
      case "Smart":
        return <ConteudoSmart />;
      case "Sites":
        return <ConteudoBasic />;
      case "Logistica":
        return <ConteudoLogistica />;
      case "Ecommerce":
        return <ConteudoEcommerce />;
      default:
        return null;
    }
  };

  return { MudancaPort, Renderizar };
};
