import React, { useState } from "react";
import { Content } from "../context/ContentPanos";
import * as S from "../styles/PlanosStyles";

export const Planos = () => {
  const { MudancaPort, Renderizar } = Content();
  const [selectedButton, setSelectedButton] = useState("Sites");

  const handleClick = (content) => {
    MudancaPort(content);
    setSelectedButton(content);
  };
  return (
    <S.PlanosSection>
      <S.PlanosSectionH5>Planos</S.PlanosSectionH5>
      <S.PlanosSectionTitle>
        Confira nossos planos e veja qual melhor opção para sua empresa
      </S.PlanosSectionTitle>
      <S.PlanosSectionText>
        Caso não tenha encontrado o plano que procura entre em contato com o
        nosso time.
      </S.PlanosSectionText>
      <S.PlanosNav>
        <S.PlanosNavLink
          className={selectedButton === "Sites" ? "selecionado" : ""}
          onClick={() => handleClick("Sites")}
        >
          Sites
        </S.PlanosNavLink>
        <S.PlanosNavLink
          className={selectedButton === "Logistica" ? "selecionado" : ""}
          onClick={() => handleClick("Logistica")}
        >
          Logistica
        </S.PlanosNavLink>
        <S.PlanosNavLink
          className={selectedButton === "Ecommerce" ? "selecionado" : ""}
          onClick={() => handleClick("Ecommerce")}
        >
          E-commerce
        </S.PlanosNavLink>
      </S.PlanosNav>
      <S.PlanosSectionContent>{Renderizar()}</S.PlanosSectionContent>
    </S.PlanosSection>
  );
};
