import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Archivo Narrow", sans-serif;
  }

  button, a, li, p, input, textarea,label,th,td,option,strong {
    font-family: "Archivo Narrow", sans-serif;  
    list-style: none;
    text-decoration: none;
    
    transition: filter 0.3s;
    &:hover {
      filter: brightness(1.2);
      transition: filter 0.3s;
    }
  }

  /* Estilos para a barra de rolagem */
  ::-webkit-scrollbar {
    width: 10px; 
    border-radius: 10px; 
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff; 
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4a5e7c; 
    border-radius: 10px; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #17293a; 
  }
`;

export const temaPrimario = "#ffffff";
export const corPrimaria = "#4a5e7c";
export const corSecundaria = "#17293a";
export const corTerciaria = "#29303c";
export const cardColor = "#B51725";
export const cardColor2 = "#C25B32";
export const cardColor3 = "#38E54D";
export const cardColor4 = "#54B435";
