import styled from "styled-components";
import {
  temaPrimario,
  corSecundaria,
  corPrimaria,
  corTerciaria,
} from "./GlobalStyles";
import Slider from "react-slick";
import { Link } from "react-router-dom";
export const PlanosSection = styled.section`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  color: ${temaPrimario};
  margin-top: -800px;
`;
export const PlanosSectionH5 = styled.h5`
  font-size: 1.4em;
  letter-spacing: 5px;
`;
export const PlanosSectionTitle = styled.h2`
  font-size: 3em;
  @media (max-width: 576px) {
    font-size: 2rem;
    padding: 10px;
  }
`;
export const PlanosSectionHr = styled.hr`
  width: 100px;
  border: 2px solid ${temaPrimario};
  margin-top: -15px;
`;
export const PlanosSectionText = styled.p`
  text-align: center;
  font-size: 22px;
  @media (max-width: 576px) {
    font-size: 1.3em;
    padding: 10px;
  }
`;
export const PlanosSectionContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 40px;
  margin: 80px auto;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 60%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 60%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 576px) {
    width: 95%;
  }
`;
export const PlanosSectionCard = styled.div`
  height: 700px;
  display: flex;
  background: ${temaPrimario};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px auto;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  &:hover {
    transition: 0.5s ease-in-out;
    transform: scale(1.1);
  }
  @media (max-width: 993px) {
    transform: none !important;
  }
`;
export const PlanosSectionCardTitle = styled.h2`
  font-size: 2em;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
`;
export const PlanosSectionCardSubTitle = styled.h3`
  font-size: 1.3em;

  color: ${corSecundaria};
`;

export const PlanosSectionCardText = styled.p`
  text-align: center;
  font-size: 1.125em;
  font-weight: 600;
  color: ${corTerciaria};
  margin: 15px;
`;
export const PlanosSectionCardHr = styled.hr`
  border: 1px solid ${corPrimaria};
  width: 80%;
  margin-top: -10px;
`;

export const PlanosSectionCardValue = styled.h2`
  font-size: 2em;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
`;
export const PlanosSectionCardLink = styled(Link)`
  cursor: pointer;
  margin-top: 100px;
  width: 50%;
  text-align: center;
  padding: 10px 20px;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  color: ${temaPrimario};
  transition: 0.5s ease-in-out;
  font-weight: 700;
  &:hover {
    transition: 0.5s ease-in-out;
    border: 2px solid ${corSecundaria};
    background: ${temaPrimario};
    color: ${corTerciaria};
  }
`;
export const PlanosNav = styled.nav`
  margin: 50px auto;
  font-size: 1.5em;
  width: 60%;
  @media (min-width: 577px) and (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
export const PlanosNavLink = styled(Link)`
  font-size: 1.2em;
  font-weight: 700;
  margin: 10px;
  color: ${temaPrimario};
  cursor: pointer;
  transition: 0.7s ease-in-out;
  &:hover {
    color: ${corPrimaria};
    filter: brightness(1.5);
  }
  &.selecionado {
    color: ${corPrimaria};
    border-bottom: 2px solid ${temaPrimario};
    filter: brightness(1.5);
  }
`;
export const PlanosCarrousel = styled.div`
  padding: 10px;
`;

export const SliderPlanos = styled(Slider)``;

export const LinkPlanos = styled(Link)`
  font-size: 1em;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
`;
