import React from "react";

import Rocket from "../images/comece.png";
import Web from "../images/www.png";
import Logistica from "../images/logistica.png";
import Ecommerce from "../images/ecommerce.png";
import * as S from "../styles/ServicoStyles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PlanosCarrousel, SliderPlanos } from "../styles/PlanosStyles";

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const Servicos = () => {
  return (
    <>
      <S.ServicosSection>
        <S.ServicosDiv>
          <S.ServicosIcon src={Rocket} />
          <S.ServicosText>
            Construa sua presença online e alcance mais clientes com nosso
            serviço de criação de sites e ecommerce. Aumente suas vendas e
            expanda seus negócios de forma rápida e eficiente. Comece hoje mesmo
            e leve sua loja para o próximo nível!
          </S.ServicosText>
          <S.ServicosLink to="/servicos">Ver Serviços</S.ServicosLink>
        </S.ServicosDiv>
        <S.ServicosDivContent>
          <S.ServicosSubTitle>Serviços</S.ServicosSubTitle>
          <S.ServicosTitle>
            Temos a Melhor Solução para o seu negócio
            <S.ServicosText2>
              Nós já sabemos que todos passam a maior parte do tempo na
              internet, não acha que já passou da hora de você se posicionar da
              maneira certa no digital? Aproveite as vantagens que esse meio
              oferece!
            </S.ServicosText2>
          </S.ServicosTitle>
        </S.ServicosDivContent>
        <S.CardsContent>
          <SliderPlanos {...settings}>
            <PlanosCarrousel>
              <S.CardsDiv>
                <S.CardsDivImg src={Web} alt="Sites" />
                <S.CardsDivH3>Criação de Sites</S.CardsDivH3>
                <S.CardsDivP>
                  Transforme sua ideia em realidade digital conosco.
                  Desenvolvemos sites personalizados e responsivos para destacar
                  sua presença online.
                </S.CardsDivP>
                <S.CardsDivLink
                  to="/servicos/planos/sites"
                  style={{ marginTop: "125px" }}
                >
                  Ver Mais
                </S.CardsDivLink>
              </S.CardsDiv>
            </PlanosCarrousel>
            <PlanosCarrousel>
              <S.CardsDiv>
                <S.CardsDivImg src={Logistica} alt="Logistica" />
                <S.CardsDivH3>Logística</S.CardsDivH3>
                <S.CardsDivP>
                  Potencialize sua logística para comércio eletrônico conosco.
                  Oferecemos soluções personalizadas e eficientes para garantir
                  a entrega rápida e confiável dos seus produtos aos clientes.
                </S.CardsDivP>
                <S.CardsDivLink to="/servicos/planos/logistica">
                  Ver Mais
                </S.CardsDivLink>
              </S.CardsDiv>
            </PlanosCarrousel>
            <PlanosCarrousel>
              <S.CardsDiv>
                <S.CardsDivImg src={Ecommerce} alt="Ecommerce" />
                <S.CardsDivH3>E-commerce</S.CardsDivH3>
                <S.CardsDivP>
                  Transforme sua ideia em um site de comércio eletrônico de
                  sucesso conosco. Desenvolvemos sites personalizados e
                  responsivos para destacar sua presença online
                </S.CardsDivP>
                <S.CardsDivLink to="/servicos/planos/ecommerce">
                  Ver Mais
                </S.CardsDivLink>
              </S.CardsDiv>
            </PlanosCarrousel>
          </SliderPlanos>
        </S.CardsContent>
      </S.ServicosSection>
      <S.CardsContentFaixa></S.CardsContentFaixa>
    </>
  );
};
