import styled from "styled-components";
import { corPrimaria, corSecundaria, corTerciaria } from "./GlobalStyles";
import { Link } from "react-router-dom";

export const SaibaMaisSection = styled.section`
  max-width: 1200px;
  width: 100%;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  @media (min-width: 993px) and (max-width: 1200px) {
    flex-direction: column;
    width: 90%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    flex-direction: column;
    width: 90%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    width: 90%;
  }
`;
export const SaibaMaisDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;
export const SaibaMaisSubTitle = styled.h5`
  font-size: 1.5em;
  letter-spacing: 5px;
  color: ${corSecundaria};
  @media (max-width: 576px) {
    font-size: 1.2em;
    padding: 10px;
  }
`;
export const SaibaMaisTitle = styled.h2`
  font-size: 3em;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: 576px) {
    font-size: 2.3em;
    padding: 10px;
  }
`;

export const SaibaMaisContato = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
    width: 80%;
    margin-left: -160px;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    width: 80%;
    margin-left: -100px;
  }
`;
export const SaibaMaisText = styled.p`
  width: 90%;
  text-align: justify;
  font-size: 1.2em;
  color: ${corTerciaria};
  font-weight: 600;
  @media (max-width: 576px) {
    width: 100%;
    font-size: 1em;
    padding: 10px;
  }
`;
export const SaibaMaisIcon = styled(Link)`
  margin: 10px;
  width: 25%;
  font-weight: 700;
  color: ${corTerciaria};
  cursor: pointer;
  @media (min-width: 577px) and (max-width: 768px) {
    margin-left: 180px;
    margin-top: -40px;
  }
  @media (max-width: 576px) {
    margin-top: -25px;
    margin-left: 180px;
    width: 34%;
  }
`;
export const SaibaMaisImg = styled.img`
  cursor: pointer;
  @media (min-width: 577px) and (max-width: 768px) {
    padding: 10px;
  }
`;
export const SaibaMaisVideoDiv = styled.div``;
export const SaibaMaisVideo = styled.video`
  border: 10px solid;
  width: 30vw;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  border-image: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  border-image-slice: 1;
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 100%;
    align-self: center;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 100%;
    align-self: center;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 100%;
    align-self: center;
  }
  @media (max-width: 576px) {
    width: 100%;
    align-self: center;
  }
`;
