import styled from "styled-components";
import Basic from "../images/basic.svg";
import Basic1 from "../images/logistica.svg";
import Basic2 from "../images/ecommerce.svg";
import Basic3 from "../images/back.jpg";

import {
  cardColor,
  cardColor2,
  corPrimaria,
  corSecundaria,
} from "./GlobalStyles";

export const ConteudoSections = styled.section`
  max-width: 100%;
  margin: 0 auto;
  height: auto;
`;

export const BasicImg = styled.img`
  width: 100%;
  height: 650px;
  background-image: url(${Basic});
  background-size: cover;
  border: none;
  box-sizing: border-box;
  background-position: center;
  margin: 0 auto;
  filter: drop-shadow(0.8);
`;
export const BasicImg1 = styled.img`
  width: 100%;
  height: 650px;
  background-image: url(${Basic1});
  background-size: cover;
  border: none;
  box-sizing: border-box;
  background-position: center;
  margin: 0 auto;
  filter: drop-shadow(0.8);
`;
export const BasicImg2 = styled.img`
  width: 100%;
  height: 650px;
  background-image: url(${Basic2});
  background-size: cover;
  border: none;
  box-sizing: border-box;
  background-position: center;
  margin: 0 auto;
  filter: drop-shadow(0.8);
`;
export const BasicImg3 = styled.img`
  width: 100%;
  height: 650px;
  background-image: url(${Basic3});
  background-size: cover;
  border: none;
  box-sizing: border-box;
  background-position: center;
  margin: 0 auto;
  filter: drop-shadow(0.8);
`;

export const ConteudoTitle = styled.h2`
  margin-top: 50px;
  font-size: 3em;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
`;
export const ConteudoTitle1 = styled.h2`
  margin-top: 50px;
  font-size: 3em;
  background: linear-gradient(to right, ${corPrimaria}, ${cardColor2});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
`;
export const ConteudoTitle2 = styled.h2`
  margin-top: 50px;
  font-size: 3em;
  background: linear-gradient(to right, ${corPrimaria}, ${cardColor});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
`;
export const Accordion = styled.div`
  margin: 100px auto;
  height: auto;
  max-width: 1200px;
`;
export const AccordionItem = styled.div``;
export const AccordionTitle = styled.h2``;
export const AccordionButton = styled.button`
  font-size: 0.8em;
  font-weight: 700;
  color: ${corPrimaria};
`;
export const AccordionColapse = styled.div``;
export const AccordionBody = styled.div`
  font-family: "Archivo Narrow", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
`;
