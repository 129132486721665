import styled from "styled-components";
import Basic3 from "../images/trabalho.jpg";
import { corPrimaria, corSecundaria } from "./GlobalStyles";
import { Link } from "react-router-dom";
export const ContentClientes = styled.section``;
export const BasicImg3 = styled.img`
  width: 100%;
  height: 650px;
  background-image: url(${Basic3});
  background-size: cover;
  border: none;
  box-sizing: border-box;
  background-position: center;
  margin: 0 auto;
  filter: drop-shadow(0.8);
`;

export const NossosClientesTitle = styled.h2`
  text-align: center;
  font-size: 3em;
  margin-top: 20px;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: 576px) {
    font-size: 2rem;
    padding: 10px;
  }
`;

export const CardContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImagemCard = styled.img`
  aspect-ratio: 1;
  padding: 10px;
`;
export const ContentImagem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkSmart = styled(Link)`
  color: ${corPrimaria};
`;
