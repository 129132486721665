import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { ConteudoLogistica } from "../components/ConteudoPlanos2";
import { Helmet } from "react-helmet";

export const SitesLogistica = () => {
  return (
    <>
      <Helmet>
        <title>Otimize a logística do seu e-commerce</title>
        <meta
          name="description"
          content="Otimize a logística do seu e-commerce com nossas soluções especializadas. Oferecemos gestão de estoque online, processamento de pedidos eficiente, embalagem personalizada e entrega rápida. Conte com nossa expertise em logística reversa, rastreamento de envios e redução de custos logísticos para impulsionar o sucesso do seu negócio. Saiba como melhorar a eficiência da sua cadeia de suprimentos e garantir uma entrega última milha confiável."
        />
      </Helmet>
      <Header2 />
      <ConteudoLogistica />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
