import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { PoliticaComponents } from "../components/Politica";
import { Helmet } from "react-helmet";
export const Politica = () => {
  return (
    <>
      <Helmet>
        <title>Política de Privacidade</title>
        <meta
          name="description"
          content="Conheça nossa Política de Privacidade: Comprometemo-nos a proteger os dados pessoais dos usuários de acordo com as leis aplicáveis. Descrevemos como coletamos, armazenamos e utilizamos dados, e como garantimos a segurança das informações. Ao aceitar esta política, você concorda com nossos termos e condições, incluindo o uso de cookies. Garantimos transparência e respeito aos direitos dos usuários. Entre em contato conosco para quaisquer dúvidas ou solicitações relacionadas à privacidade dos seus dados."
        />
      </Helmet>
      <Header2 />
      <PoliticaComponents />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
