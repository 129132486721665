import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { ContentSobre } from "../components/ContentSobre";
import { Helmet } from "react-helmet";
export const Sobre = () => {
  return (
    <>
      <Helmet>
        <title>Sobre</title>
        <meta
          name="description"
          content="Conheça a história da E-Commerce Smart: uma pioneira desde 2017 no desenvolvimento de soluções completas para e-commerce. Fundada e liderada por Felipe A. S. Camargo, destacamo-nos pela inovação e compromisso com a excelência em todas as etapas. Oferecemos suporte contínuo, compromisso com a qualidade e visão de futuro para ser sua parceira preferencial no sucesso do comércio eletrônico."
        />
      </Helmet>
      <Header2 />
      <ContentSobre />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
