import styled from "styled-components";
import { corPrimaria, corSecundaria } from "../styles/GlobalStyles";
export const PoliticaSections = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;
export const PoliticaTitle = styled.h2`
  font-size: 3em;
  margin: 50px;
  text-align: center;
  background: linear-gradient(to right, ${corPrimaria}, ${corSecundaria});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: 576px) {
    font-size: 2rem;
    padding: 10px;
  }
`;
export const PoliticaText = styled.p`
  text-align: justify;
  font-size: 1em;
  font-weight: 500;
  padding: 10px;
`;
export const Lista = styled.li`
  list-style: initial;
`;
