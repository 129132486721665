import styled from "styled-components";
import {
  corPrimaria,
  corSecundaria,
  corTerciaria,
  temaPrimario,
} from "./GlobalStyles";
import { Link } from "react-router-dom";

export const HeaderMenu = styled.header`
  width: 100%;
`;
export const HeaderMenuContent = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.menuFixo {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 6;
    height: 100px;
    background: ${temaPrimario};
    animation: fadeIn 2s ease-in-out;
    & img {
      width: 15%;
      margin-left: 5%;
    }
    & nav {
      margin-right: 1.5%;
    }
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 50%;
    justify-content: center;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    display: none;
    height: auto;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    display: none;
    height: auto;
  }
  @media (max-width: 576px) {
    display: none;
    height: auto;
  }
`;

export const HeaderMenuDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
`;
export const HeaderMenuNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const HeaderMenuLink = styled(Link)`
  font-size: 1.5em;
  font-weight: 700;
  margin: 10px;
  color: ${corSecundaria};
  cursor: pointer;
  transition: 0.7s ease-in-out;
  z-index: 2;

  &:hover {
    transition: 0.7s ease-in-out;
    color: ${corPrimaria};
    filter: brightness(1.5);
  }
`;
export const HeaderMenuImg = styled.img`
  width: 150px;
  filter: brightness(1.5);
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 90px;
    margin-left: -120px;
  }
`;

export const VideoMenu = styled.video`
  width: 100%;
  margin-top: -150px;
  height: 900px;
  object-fit: cover;
  z-index: -1;
  border-bottom: 18px solid black;
  border-image: linear-gradient(
    to right,
    ${corPrimaria} 0%,
    ${corSecundaria} 20%,
    ${corTerciaria} 40%,
    ${corPrimaria} 60%,
    ${corTerciaria} 80%,
    ${corPrimaria} 100%
  );
  border-image-slice: 1;
  transition: padding-top 0.5s ease-in-out;
`;
export const ContentTitle = styled.h1`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3.5rem;
  color: ${corSecundaria};
  @media (min-width: 993px) and (max-width: 1200px) {
    font-size: 2.5rem;
    width: 100%;
    margin-top: -100px;
    margin-left: -40px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 2.5rem;
    width: 100%;
    margin-top: -100px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    font-size: 2rem;
    width: 100%;
    margin-top: -100px;
  }
  @media (max-width: 576px) {
    font-size: 1.5rem;
    width: 100%;
    margin-top: -100px;
  }
`;
export const ContentText = styled.span`
  color: white;
`;
export const LogoLink = styled(Link)``;
