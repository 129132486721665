import React from "react";
import * as S from "../styles/ContentSobre";
import Logo from "../images/logo.svg";
import Binoculos from "../images/binoculos.png";
import Suporte from "../images/apoio-suporte.png";

import Qualidade from "../images/servico-de-qualidade.png";
import {
  DiferencialCard,
  DiferencialCardImg,
  DiferencialCardText,
  DiferencialCardTitle,
  DiferencialContent,
} from "../styles/DiferencialStyles";
export const ContentSobre = () => {
  return (
    <>
      <S.ContentSectionFaixa>
        <S.ContentSection>
          <S.ContentTotal>
            <S.ContentTitleCard>
              <S.ContentTitle>Sobre a E-Commerce Smart</S.ContentTitle>
              <S.ContentText>
                Desde 2017, a E-Commerce Smart tem sido uma pioneira no
                desenvolvimento de soluções completas para e-commerce,
                oferecendo uma gama de serviços que abrangem desde a criação de
                sites até a gestão logística. Fundada e liderada por Felipe A.
                S. Camargo, a empresa se destaca por sua abordagem inovadora e
                compromisso com a excelência em todos os aspectos de seus
                serviços.
              </S.ContentText>
            </S.ContentTitleCard>
            <S.ContentLogoCard>
              <S.ContentLogo src={Logo} alt="Loco Ecommerce Smart" />
            </S.ContentLogoCard>
          </S.ContentTotal>
        </S.ContentSection>
        <S.ContentCard>
          <DiferencialContent>
            <DiferencialCard style={{ border: "none" }}>
              <DiferencialCardImg src={Suporte} alt="Suporte" />
              <DiferencialCardTitle>Suporte Contínuo</DiferencialCardTitle>
              <DiferencialCardText>
                Além de fornecer soluções iniciais, a empresa oferece suporte
                contínuo e atualizações para garantir que os clientes estejam
                sempre à frente das últimas tendências e tecnologias.
              </DiferencialCardText>
            </DiferencialCard>
            <DiferencialCard style={{ border: "none" }}>
              <DiferencialCardImg src={Qualidade} alt="Qualidade" />
              <DiferencialCardTitle>
                Compromisso com a Qualidade
              </DiferencialCardTitle>
              <DiferencialCardText>
                A E-Commerce Smart está comprometida em fornecer serviços da
                mais alta qualidade, mantendo um padrão de excelência em todas
                as etapas do processo. Seja na criação de sites visualmente
                impressionantes ou na entrega pontual de pedidos, a empresa se
                esforça para superar as expectativas dos clientes.
              </DiferencialCardText>
            </DiferencialCard>
            <DiferencialCard style={{ border: "none" }}>
              <DiferencialCardImg src={Binoculos} alt="Visão" />
              <DiferencialCardTitle>Visão Futura</DiferencialCardTitle>
              <DiferencialCardText>
                A E-Commerce Smart está continuamente buscando inovação e
                crescimento, expandindo sua oferta de serviços e explorando
                novas oportunidades de mercado. A empresa tem como objetivo se
                tornar a parceira preferencial de empresas que buscam sucesso no
                comércio eletrônico em um mundo cada vez mais digitalizado.
              </DiferencialCardText>
            </DiferencialCard>
          </DiferencialContent>
        </S.ContentCard>
      </S.ContentSectionFaixa>
    </>
  );
};
