import styled from "styled-components";
import {
  temaPrimario,
  corSecundaria,
  corPrimaria,
  corTerciaria,
  cardColor,
} from "./GlobalStyles";
import { Link } from "react-router-dom";
export const PlanosSectionCard = styled.div`
  height: 700px;
  display: flex;
  background: ${temaPrimario};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px auto;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  &:hover {
    transition: 0.5s ease-in-out;
    transform: scale(1.1);
  }
  @media (max-width: 993px) {
    transform: none !important;
  }
`;
export const PlanosSectionCardTitle = styled.h2`
  font-size: 2em;
  background: linear-gradient(to right, ${corPrimaria}, ${cardColor});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
`;
export const PlanosSectionCardSubTitle = styled.h3`
  font-size: 1.3em;
  color: ${corSecundaria};
`;

export const PlanosSectionCardText = styled.p`
  text-align: center;
  font-size: 1.125em;
  font-weight: 600;
  color: ${corTerciaria};
  margin: 15px;
`;
export const PlanosSectionCardHr = styled.hr`
  border: 1px solid ${corPrimaria};
  width: 80%;
  margin-top: -10px;
`;

export const PlanosSectionCardValue = styled.h2`
  font-size: 2em;
  background: linear-gradient(to right, ${corPrimaria}, ${cardColor});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
`;
export const PlanosSectionCardLink = styled(Link)`
  cursor: pointer;
  margin-top: 100px;
  width: 50%;
  text-align: center;
  padding: 10px 20px;
  background: linear-gradient(to right, ${corPrimaria}, ${cardColor});
  color: ${temaPrimario};
  transition: 0.5s ease-in-out;
  font-weight: 700;
  &:hover {
    border: 2px solid ${cardColor};
    transition: 0.5s ease-in-out;
    background: ${temaPrimario};
    color: ${corTerciaria};
  }
`;
export const LinkPlanosValue = styled(Link)`
  font-size: 1em;
  background: linear-gradient(to right, ${corPrimaria}, ${cardColor});
  background-clip: padding-box;
  -webkit-background-clip: text;
  color: transparent;
`;
