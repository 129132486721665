import React from "react";
import { Header2 } from "../components/Header2";
import { BotaoFixo } from "../context/BotaoFixo";
import { Rodape } from "../components/Rodape";
import { Helmet } from "react-helmet";
import { ConteudoSmart } from "../components/ConteudoPlanos4";

export const SitesSmart = () => {
  return (
    <>
      <Helmet>
        <title>Smart Gerencia</title>
        <meta
          name="description"
          content="A Smart Gerencia é a escolha ideal para empresas que buscam otimizar suas operações logísticas, comerciais e digitais. Nossos serviços abrangem uma ampla gama de soluções, garantindo eficiência e crescimento sustentável para o seu negócio."
        />
      </Helmet>
      <Header2 />
      <ConteudoSmart />
      <BotaoFixo />
      <Rodape />
    </>
  );
};
