import styled from "styled-components";
export const ErroSection = styled.section`
  max-width: 1200px;
  margin: 100px auto;
`;
export const ErroImg = styled.img`
  width: 50%;
  display: block;
  margin: 0 auto;
`;
